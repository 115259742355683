import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { checkUserLogged } from "../Helper";
import { getWithAuth, endpointUser } from "../HttpUtils";

const UserAttendanceDetails = ({ userId }) => {
  const history = useHistory();
  const [hollyName, setHollyName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [t3t5HistoryList, setT3T5HistoryList] = useState([]);
  const [giaoLyHistoryList, setGiaoLyHostiryList] = useState([]);
  const [leSomHistoryList, setLeSomHistoryList] = useState([]);
  const [khacHistoryList, setKhacHistoryList] = useState([]);

  useEffect(() => {
    let logged = checkUserLogged();
    if (!logged) {
      history.push("/account/signin");
      return;
    }
    fetchAttendanceDetailsByUserId();
  }, []);

  const fetchAttendanceDetailsByUserId = () => {
    getWithAuth(endpointUser + "/statistic-details/" + userId)
      .then((response) => {
        if (response.status === 200) {
          setHollyName(response.data[0].hollyName);
          setLastName(response.data[0].lastName);
          setFirstName(response.data[0].firstName);
          setT3T5HistoryList(response.data[0].history);
          setGiaoLyHostiryList(response.data[1].history);
          setLeSomHistoryList(response.data[2].history);
          setKhacHistoryList(response.data[3].history);
        }
      })
      .catch((error) => console.log("Fetching statistic error: " + error));
  };

  return (
    <div>
      <Row>
        <h3 className="alert alert-danger" align="center">
          LỊCH SỬ ĐIỂM DANH
        </h3>
      </Row>

      <div className="table-responsive">
        <table style={{ width: "30rem" }}>
          <thead>
            <tr>
              <th>Mã thiếu nhi</th>
              <th>Tên Thánh</th>
              <th>Họ & tên đệm</th>
              <th>Tên</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{userId}</td>
              <td>{hollyName}</td>
              <td>{lastName}</td>
              <td>{firstName}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <br />
        <br />
        <hr />
      </div>

      {t3t5HistoryList.length > 0 && (
        <div className="table-responsive" style={{ marginTop: "2rem" }}>
          <h5>Thứ 3, Thứ 5</h5>
          <table
            className="table table-hover table-secondary"
            style={{ width: "60rem" }}
          >
            <thead>
              <tr>
                <th>STT</th>
                <th>Ngày quét</th>
                <th>Người quét</th>
                {/* <th>Đồng bộ</th> */}
              </tr>
            </thead>
            <tbody>
              {t3t5HistoryList.map((attendance, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{attendance.scannedDate}</td>
                  <td>{attendance.scannedBy}</td>
                  {/* <td>{formatSyncedDate(attendance.syncedDate)}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </div>
      )}

      {giaoLyHistoryList.length > 0 && (
        <div className="table-responsive" style={{ marginTop: "2rem" }}>
          <h5>Học giáo lý</h5>
          <table
            className="table table-hover table-secondary"
            style={{ width: "60rem" }}
          >
            <thead>
              <tr>
                <th>STT</th>
                <th>Ngày quét</th>
                <th>Người quét</th>
                {/* <th>Đồng bộ</th> */}
              </tr>
            </thead>
            <tbody>
              {giaoLyHistoryList.map((attendance, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{attendance.scannedDate}</td>
                  <td>{attendance.scannedBy}</td>
                  {/* <td>{formatSyncedDate(attendance.syncedDate)}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </div>
      )}

      {leSomHistoryList.length > 0 && (
        <div className="table-responsive" style={{ marginTop: "2rem" }}>
          <h5>Đi Lễ CN Sớm</h5>
          <table
            className="table table-hover table-secondary"
            style={{ width: "60rem" }}
          >
            <thead>
              <tr>
                <th>STT</th>
                <th>Ngày quét</th>
                <th>Người quét</th>
                {/* <th>Đồng bộ</th> */}
              </tr>
            </thead>
            <tbody>
              {leSomHistoryList.map((attendance, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{attendance.scannedDate}</td>
                  <td>{attendance.scannedBy}</td>
                  {/* <td>{formatSyncedDate(attendance.syncedDate)}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </div>
      )}

      {khacHistoryList.length > 0 && (
        <div className="table-responsive" style={{ marginTop: "2rem" }}>
          <h5>Những trường hợp khác (đi lễ bù,...)</h5>
          <table
            className="table table-hover table-secondary"
            style={{ width: "60rem" }}
          >
            <thead>
              <tr>
                <th>STT</th>
                <th>Ngày quét</th>
                <th>Người quét</th>
                {/* <th>Đồng bộ</th> */}
              </tr>
            </thead>
            <tbody>
              {khacHistoryList.map((attendance, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{attendance.scannedDate}</td>
                  <td>{attendance.scannedBy}</td>
                  {/* <td>{formatSyncedDate(attendance.syncedDate)}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default UserAttendanceDetails;
