import React, { useState } from 'react';
import { Row, Container, Button, Col, Label, Input } from 'reactstrap';
import { Link} from "react-router-dom";
import { endpointGlv, getWithAuth } from '../../components/HttpUtils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BiAddToQueue } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import CardList from './cardList';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { formatDate, styles } from '../../components/Helper';
import ScaleLoader from "react-spinners/ScaleLoader";
import '../../App.css'

toast.configure();
const CardBySearching = () => {
    const [loading, setLoading] = useState(false);
    const [filterDate, setFilterDate] = useState('')
    const [showNonResult, setShowNonResult] = useState(false);
    const [query, setQuery] = useState("");
    const [queryResultList, setQueryResultList] = useState([]);
    const [listUserToPrint, setListUserToPrint] = useState([]);
    const [itemPerPage] = useState(8);
    const [pageNumbers, setPageNumbers] = useState([]);

    const onSearching = (event) => {
        let query = event.target.value.toLowerCase().trim();
        setQuery(query);
    };

    const queryUser = async (queryVal, dateVal) => {
        if(queryVal.trim() === '' && dateVal === '')
          return;
    
        setLoading(true)
        let queryStr = '';
        let params = {};
        if(queryVal.trim() !== ''){
            params['info'] = queryVal.trim();
        }
        if(dateVal !== ''){
            params['date'] = dateVal;
        }

        queryStr = Object
            .entries(params) // return an array of enumerable string-keyed property key-value pairs.
            .map(arrItem => arrItem.join('=')) // param1=val1, param2=val2
            .join('&'); // param1=val1&param2=val2

        await getWithAuth(`${endpointGlv}/query-user?${queryStr}`).then((response) => {
          if(response.status === 200){
            if(response.data.length === 0){
              setShowNonResult(true)
            }
            else setShowNonResult(false)
            setQueryResultList(response.data);
          }
        }).catch((error) => console.log("Query users error: " + error))
      }

    // const exportSnapshotImage = async () => {
    //     toast.info(`Vui lòng chờ trong giây lát...`, {
    //         position: toast.POSITION.TOP_CENTER,
    //         autoClose: 2000,
    //     });

    //     const inputs = document.getElementsByClassName('cards');
    //     for(let index=0; index<inputs.length; index++){
    //         const canvas = await html2canvas(inputs[index]);
    //         const base64 = canvas.toDataURL();
    //         const a = document.createElement("a");
    //         a.href = base64;
    //         a.download = `The_Thieu_Nhi_${index}.png`;
    //         a.click();
    //     }
    // }

    const exportPDFFile = async () => {
        toast.info(`Vui lòng chờ trong giây lát...`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
        });

        var doc = new jsPDF('p', 'mm');
        for(let index=0; index<pageNumbers.length; index++){
            let input = document.getElementById(`card-${index}`)
            await html2canvas(input, {scale: 2})
            .then((canvas) => {
                var imgData = canvas.toDataURL('image/png');
                var imgWidth = 210; 
                var imgHeight = canvas.height * imgWidth / canvas.width;
                var position = 10; // give some top padding to first page

                doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            });
            if(index < pageNumbers.length - 1){
                doc.addPage()
            }
        }
        doc.save(`The_Thieu_Nhi.pdf`);
    }

    const calcTotalPages = (arr) => {
        let totalPages = Math.ceil(arr.length / itemPerPage)

        let temp = []
        for(let index=0; index<totalPages; index++){
            temp.push(index)
        }
        setPageNumbers(temp)
    }

    const addToPrintList = (user) => {
        if(!isStatusAllowedToProceed(user.status)){
            alert(`Không thể thêm tình trạng ${user.status} vào danh sách!`);
            return;
        }

        const userToPrint = {
            'userId': user.user_id_result,
            'hollyName': user.holly_name_result,
            'firstName': user.first_name_result,
            'lastName': user.last_name_result,
            'birthday': user.birthday_result,
            'christenDate': user.christen_date_result
        }
        let temp = [...listUserToPrint]
        temp.push(userToPrint)
        const key = 'userId'
        let uniqueList = [...new Map(temp.map(item => [item[key], item])).values()]
        calcTotalPages(uniqueList)
        setListUserToPrint(uniqueList)
    }

    const removeFromPrintList = (val) => {
        let temp = [...listUserToPrint]                
        let newList = temp.filter(user => user.userId !== val);
        setListUserToPrint(newList)
    }

    const isStatusAllowedToProceed = (status) => {
        return status === "ĐANG HỌC";
    }

    return (
        <Container className='container'>
            <Row>
                <h3>DANH MỤC TÌM KIẾM</h3>
            </Row>

            <Row>
                <Col sm="12" md="8">
                <strong>
                    <Label for="search">Tra cứu</Label>
                </strong>
                <br></br>
                <input
                    type="search"
                    name="search"
                    style={{ width: "100%", height: "2.5rem" }}
                    placeholder="Nhập mã thiếu nhi, tên thánh, họ tên thiếu nhi, SĐT phụ huynh, họ tên phụ huynh,..."
                    onChange={onSearching}
                    onKeyDown={(e) => {
                    if (e.code === "Enter") {
                        e.preventDefault();
                        queryUser(e.target.value, filterDate).then(() => setLoading(false))
                    }
                }}
                />
                {showNonResult && <p style={{color:"blue"}}>Thông tin không tồn tại</p>}
                </Col>

                <Col sm="12" md="4">
                <div style={{ display: "inline-block" }}>
                    <strong>Chọn ngày sinh/ rửa tội: </strong>
                    <Input
                    style={{ width: "10rem", marginLeft: "1rem" }}
                    type="date"
                    onChange={(e) => setFilterDate(e.target.value)}
                    />
                </div>

                <Button color="dark" onClick={() => queryUser(query, filterDate).then(() => setLoading(false))}>Tìm</Button>
                </Col>
            </Row>

            <Row>
                <div style={styles}>
                <ScaleLoader
                    color="brown"
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>
            </Row>

            {queryResultList.length > 0 && 
            <Row style={{marginTop: "2rem"}} sm="12">
                <table className='table table-bordered table-hover' id='query-results'>
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Mã thiếu nhi</th>
                        <th>Tên Thánh</th>
                        <th>Họ, tên đệm</th>
                        <th>Tên</th>
                        <th>Lớp</th>
                        <th>Ngày sinh</th>
                        <th>Ngày rửa tội</th>
                        <th>Tình Trạng</th>
                        <th></th>
                    </tr>
                </thead>
                            
                <tbody>
                    {queryResultList.map((student, index) => (
                        <tr key={index} id={index} >
                            <td>{index+1}</td>
                            <td>
                            <Link to={{ pathname: `/user/details/` + student.user_id_result }} target="_blank">&nbsp;{student.user_id_result}</Link>
                            </td>
                            <td>{student.holly_name_result}</td>
                            <td>{student.last_name_result}</td>
                            <td>{student.first_name_result}</td>
                            <td>{student.class_id_result}</td>
                            <td>{formatDate(student.birthday_result)}</td>
                            <td>{formatDate(student.christen_date_result)}</td>
                            <td>{student.status}</td>
                            <td style={{textAlign: 'center'}}><BiAddToQueue style={{cursor: 'pointer'}} onClick={() => addToPrintList(student)}/></td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </Row>}

            {listUserToPrint.length > 0 && 
            <Row style={{marginTop: "2rem"}} sm="12">
                <h3>DANH SÁCH THẺ CẦN IN</h3>
                <table className='table table-bordered table-hover' id='print-table'>
                <thead>
                    <tr>
                        <th>STT</th>
                        <th>Mã thiếu nhi</th>
                        <th>Tên Thánh</th>
                        <th>Họ, tên đệm</th>
                        <th>Tên</th>
                        <th>Ngày sinh</th>
                        <th>Ngày rửa tội</th>
                        <th></th>
                    </tr>
                </thead>
                            
                <tbody>
                    {listUserToPrint.map((student, index) => (
                        <tr key={index} id={index} >
                            <td>{index+1}</td>
                            <td>
                            <Link to={{ pathname: `/user/details/` + student.userId }} target="_blank">&nbsp;{student.userId}</Link>
                            </td>
                            <td>{student.hollyName}</td>
                            <td>{student.lastName}</td>
                            <td>{student.firstName}</td>
                            <td>{formatDate(student.birthday)}</td>
                            <td>{formatDate(student.christenDate)}</td>
                            <td style={{textAlign: 'center'}}><AiFillDelete style={{cursor: 'pointer'}} onClick={() => removeFromPrintList(student.userId.trim())}/></td>
                        </tr>
                    ))}
                </tbody>
                </table>
            </Row>}

            {pageNumbers.map(num => 
            <Row className='cards' key={`card-${num}`} id={`card-${num}`}>
                <CardList userList={[...listUserToPrint].slice(itemPerPage*num, itemPerPage*(num+1))}/>
                <hr style={{border: '2px solid red'}}/>
            </Row>)}

            {listUserToPrint.length > 0 && 
            <Row>
                <hr/>
                <div style={{display: 'inline-block'}}>
                    <Button color="info" onClick={exportPDFFile}>
                        XUẤT FILE THẺ
                    </Button>

                    {/* <Button style={{marginLeft: '0.2rem'}} color="warning" onClick={exportSnapshotImage}>
                        TẠO INFOGRAPHIC
                    </Button> */}
                </div>
            </Row>}
        </Container>
    );
}

export default CardBySearching;