import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import "react-tabs/style/react-tabs.css";

import { endpointPublic, get } from "../../components/HttpUtils";
import { checkUserLogged } from "../../components/Helper";
import Tabs from "../../components/Tabs/Tabs";
import { BiSync } from "react-icons/bi";
import AttendanceDateSelector from "./AttendanceDateSelector";
import AttendanceByUserId from "./AttendanceByUserId";
import AttendanceByScanner from "./AttendanceByScanner";
import AttendanceStatisticAll from "./AttendanceStatisticAll";
import AttendanceCreatorModal from "./AttendanceCreatorModal";
import "./style.css";

const AttendanceManagement = () => {
  const history = useHistory();
  const [updatedDateTime, setUpdatedDateTime] = useState("");

  const tabs = [
    {
      id: 1,
      tabTitle: "TÌM THEO MÃ THIẾU NHI",
      title: "",
      content: <AttendanceByUserId />,
    },
    // {
    //     "id": 2,
    //     "tabTitle": "THỐNG KÊ TRONG THÁNG",
    //     "title": "",
    //     "content": <AttendanceStatisticInMonth/>
    // },
    {
      id: 3,
      tabTitle: "TỔNG KẾT",
      title: "",
      content: <AttendanceStatisticAll />,
    },
    {
      id: 4,
      tabTitle: "XEM THEO NGÀY",
      title: "",
      content: <AttendanceDateSelector useForCompetition={false} />,
    },
    {
      id: 5,
      tabTitle: "TÌM THEO GLV QUÉT",
      title: "",
      content: <AttendanceByScanner />,
    },
    // {
    //     "id": 6,
    //     "tabTitle": "THI ĐUA",
    //     "title": "",
    //     "content": <AttendanceDateRangeFetcher useForCompetition={true}/>
    // },
    // {
    //     "id": 7,
    //     "tabTitle": "BẢNG XẾP HẠNG",
    //     "title": "",
    //     "content": <AttendanceStatisticRank/>
    // }
  ];

  useEffect(() => {
    let logged = checkUserLogged();
    if (!logged) {
      history.push("/account/signin");
      return;
    }
    getUpdatedAttendanceDateTime();
  }, []);

  const getUpdatedAttendanceDateTime = () => {
    get(endpointPublic + "/attendance/updated-at")
      .then((response) => {
        if (response.status === 200) {
          setUpdatedDateTime(response.data);
        }
      })
      .catch((error) =>
        console.log("Fetching attendance updated datetime error: " + error),
      );
  };

  const syncData = () => {
    document.getElementById("span-sync").innerHTML = "Chờ !!!";
    get(endpointPublic + "/refresh-attendance-list")
      .then((response) => {
        if (response.status === 200) {
          document.getElementById("span-sync").style.color = "green";
          document.getElementById("span-sync").innerHTML = "Đã đồng bộ";
          getUpdatedAttendanceDateTime();
        }
      })
      .catch((error) =>
        console.log("Fetching current classes error: " + error),
      );
  };

  return (
    <div>
      <Row>
        <h3 className="alert alert-warning" align="center">
          TỔNG KẾT ĐIỂM DANH
        </h3>
        <br />
        <hr />
      </Row>

      {updatedDateTime !== "" && (
        <Row>
          <p>
            <u>Dữ liệu cập nhật vào lúc:</u>
            <strong style={{ marginLeft: "0.2rem" }}>
              {updatedDateTime.replace("+0700", "")}
            </strong>
          </p>
        </Row>
      )}

      <Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ marginRight: "1rem", display: "flex" }}>
            <AttendanceCreatorModal
              className="modal-content"
              color="gray"
              buttonLabel="Tạo điểm danh"
              title="Tạo điểm danh"
            />
          </div>

          <div style={{ cursor: "pointer" }} onClick={() => syncData()}>
            <BiSync color="green" />
            <span id="span-sync" style={{ color: "blue" }}>
              Tải dữ liệu
            </span>
          </div>
        </div>
        <br />
      </Row>

      <Row>
        <Tabs tabs={tabs} mdProp={12}></Tabs>
      </Row>
    </div>
  );
};

export default AttendanceManagement;
