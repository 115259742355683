import axios from "axios";
import { toast } from "react-toastify";

// local host configuration
// export const hostFrontend = "http://localhost:3000/";
// export const hostBackend = 'http://localhost:8080/api';

// ubuntu host configuration
export const hostFrontend =
  process.env.REACT_APP_HOST_FRONTEND || "http://quanlythieunhi.com/";
export const hostBackend =
  process.env.REACT_APP_HOST_BACKEND || "http://quanlythieunhi.com:3000/api";

export const endpointUser = hostBackend + "/users";
export const endpointAdmin = hostBackend + "/admin";
export const endpointGlv = hostBackend + "/glv";
export const endpointPublic = hostBackend + "/public";
export const endpointAuth = hostBackend + "/auth";

export function get(url) {
  return axios.get(url);
}

export function getBinaryValue(url) {
  var config = {
    responseType: "arraybuffer",
  };
  return axios.get(url, config);
}

export function downloadBinary(url) {
  var config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json; charset=UTF-8",
    },
  };
  return axios.get(url, config);
}

export function getWithAuth(url) {
  var config = {
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  return axios.get(url, config);
}

export function post(url, body) {
  var config = {
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json; charset=UTF-8",
    },
  };
  return axios.post(url, JSON.stringify(body), config);
}

export function postwithAuth(url, body) {
  var config = {
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios.post(url, JSON.stringify(body), config);
}

export function putWithAuth(url, body) {
  var config = {
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios.put(url, JSON.stringify(body), config);
}

export function deleteWithAuth(url) {
  var config = {
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return axios.delete(url, config);
}

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.data.message.includes("JWT expired")
    ) {
      toast.configure();
      toast.error(`Hết phiên đăng nhập, vui lòng đăng nhập lại...`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setTimeout(() => {
        window.localStorage.removeItem("userId");
        window.localStorage.removeItem("token");
        window.localStorage.removeItem("role");
        window.location.replace(hostFrontend);
      }, 2000);
      return;
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  },
);
