import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from "reactstrap";
import {
  get,
  endpointGlv,
  getWithAuth,
  postwithAuth,
  endpointPublic,
  endpointAdmin,
} from "../../components/HttpUtils";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { BiAddToQueue } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import ScaleLoader from "react-spinners/ScaleLoader";
import "react-toastify/dist/ReactToastify.css";
import { messages } from "../../components/message";
import { formatDate, styles } from "../../components/Helper";

toast.configure();
const LeaveModal = (props) => {
  const { buttonLabel, className, title, color, onMergeLeave } = props;

  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [filterLeaveType, setFilterLeaveType] = useState("N35");
  const [reason, setReason] = useState("");
  const [compensateDate, setCompensateDate] = useState("");
  const [absentDate, setAbsentDate] = useState("");
  const [notes, setNotes] = useState("");
  const [showNonResult, setShowNonResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [query, setQuery] = useState("");
  const [queryResultList, setQueryResultList] = useState([]);
  const [listUserToLeave, setListUserToLeave] = useState([]);
  const [activeAcademicYear, setActiveAcademicYear] = useState("");

  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      getCurrentAcademicYear();
    }
  };

  const onSearching = (event) => {
    let query = event.target.value.toLowerCase().trim();
    setQuery(query);
  };

  const queryUser = async (queryVal, dateVal) => {
    if (queryVal.trim() === "" && dateVal === "") return;

    setLoading(true);
    let queryStr = "";
    let params = {};
    if (queryVal.trim() !== "") {
      params["info"] = queryVal.trim();
    }
    if (dateVal !== "") {
      params["date"] = dateVal;
    }

    queryStr = Object.entries(params) // return an array of enumerable string-keyed property key-value pairs.
      .map((arrItem) => arrItem.join("=")) // param1=val1, param2=val2
      .join("&"); // param1=val1&param2=val2

    await getWithAuth(`${endpointGlv}/query-user?${queryStr}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length === 0) {
            setShowNonResult(true);
          } else setShowNonResult(false);
          setQueryResultList(response.data);
        }
      })
      .catch((error) => console.log("Query users error: " + error));
  };

  const addToLeaveList = (user) => {
    const userToAddLeave = {
      userId: user.user_id_result,
      hollyName: user.holly_name_result,
      firstName: user.first_name_result,
      lastName: user.last_name_result,
      classId: user.class_id_result,
      birthday: user.birthday_result,
      christenDate: user.christen_date_result,
    };
    let temp = [...listUserToLeave];
    temp.push(userToAddLeave);
    const key = "userId";
    let uniqueList = [
      ...new Map(temp.map((item) => [item[key], item])).values(),
    ];
    setListUserToLeave(uniqueList);
  };

  const removeFromPrintList = (val) => {
    let temp = [...listUserToLeave];
    let newList = temp.filter((user) => user.userId !== val);
    setListUserToLeave(newList);
  };

  const getCurrentAcademicYear = () => {
    get(endpointPublic + "/active-academic-year")
      .then((response) => {
        if (response.status === 200) {
          setActiveAcademicYear(response.data);
        }
      })
      .catch((error) =>
        console.log("Fetching current classes error: " + error),
      );
  };

  const validateForm = () => {
    let errors = {},
      formIsValid = true;
    if (reason === undefined || reason === null || reason === "") {
      errors["reason"] = "Vui lòng điền lý do";
      formIsValid = false;
    }
    if (
      filterLeaveType === undefined ||
      filterLeaveType === null ||
      filterLeaveType === ""
    ) {
      errors["filterLeaveType"] = "Vui lòng chọn loại phép";
      formIsValid = false;
    }

    if (filterLeaveType === "NGL" && absentDate === "") {
      errors["absentDate"] = "Vui lòng chọn ngày nghỉ";
      formIsValid = false;
    }

    if (listUserToLeave.length === 0) {
      errors["users"] = "Vui lòng thêm danh sách nghỉ";
      formIsValid = false;
    }

    setErrors(errors);

    return formIsValid;
  };

  const addLeave = () => {
    if (!validateForm()) return;

    if (listUserToLeave.length === 0) {
      alert("Vui lòng nhập danh sách thiếu nhi");
    }

    setLoading(true);
    let leaveListDto = [];
    for (const leave of listUserToLeave) {
      let data = {
        reason: reason,
        compensateDate: compensateDate,
        absentDate: absentDate,
        notes: notes,
        leaveTypeId: filterLeaveType,
        academicYearId: activeAcademicYear.academicYearId,
        userId: leave.userId,
      };
      leaveListDto.push(data);
    }

    let newLeaveList = [];

    postwithAuth(endpointAdmin + "/leaves/list", leaveListDto)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          toast.success(messages.insertSuccess, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          newLeaveList = response.data;
        }
      })
      .catch((error) => {
        console.log("error inserting new user's leaves: " + error);
      })
      .finally(() => setLoading(false));

    setTimeout(function () {
      onMergeLeave(newLeaveList, false);
      toggle();
      setListUserToLeave([]);
      setQueryResultList([]);
    }, 2000);
  };

  return (
    <div>
      <Button color={color} onClick={toggle}>
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="classId">Loại nghỉ phép</Label>
                  <Input
                    type="select"
                    name="leave-type"
                    id="leave-type"
                    onChange={(e) => setFilterLeaveType(e.target.value)}
                  >
                    <option
                      key={"N35"}
                      value={"N35"}
                      selected={filterLeaveType === "N35"}
                    >
                      Nghỉ lễ Thứ 3, Thứ 5
                    </option>
                    <option
                      key={"NGL"}
                      value={"NGL"}
                      selected={filterLeaveType === "NGL"}
                    >
                      Nghỉ học giáo lý
                    </option>
                    <span style={{ color: "red" }}>
                      {errors["filterLeaveType"]}
                    </span>
                  </Input>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup hidden={filterLeaveType === "NGL"}>
                  <Label for="compensateDate">Ngày đi bù</Label>
                  <Input
                    type="text"
                    name="compensateDate"
                    value={compensateDate}
                    maxLength="50"
                    id="compensateDate"
                    placeholder="Nhập ngày đi bù"
                    onChange={(e) => setCompensateDate(e.target.value)}
                  />
                </FormGroup>
                <FormGroup hidden={filterLeaveType === "N35"}>
                  <Label for="absentDate">Ngày vắng</Label>
                  <Input
                    type="date"
                    name="absentDate"
                    value={absentDate}
                    maxLength="50"
                    id="absentDate"
                    placeholder="Nhập Ngày vắng"
                    onChange={(e) => setAbsentDate(e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["absentDate"]}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="reason">Lý do nghỉ</Label>
                  <Input
                    type="text"
                    name="reason"
                    value={reason}
                    maxLength="100"
                    id="reason"
                    placeholder="Nhập lý do"
                    onChange={(e) => setReason(e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["reason"]}</span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="notes">Ghi chú phụ</Label>
                  <Input
                    type="text"
                    name="notes"
                    value={notes}
                    maxLength="100"
                    id="notes"
                    placeholder="Nhập ghi chú phụ"
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <span style={{ color: "red" }}>{errors["user"]}</span>
            <br />
            <br />
            <hr />

            <h5>THÊM DANH SÁCH NGHỈ PHÉP</h5>
            <Row>
              <Col sm="12" md="8">
                <strong>
                  <Label for="search">Tra cứu</Label>
                </strong>
                <br></br>
                <input
                  type="search"
                  name="search"
                  style={{ width: "100%", height: "2.5rem" }}
                  placeholder="Nhập mã thiếu nhi, tên thánh, họ tên thiếu nhi, SĐT phụ huynh, họ tên phụ huynh,..."
                  onChange={onSearching}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") {
                      e.preventDefault();
                      queryUser(e.target.value, filterDate).then(() =>
                        setLoading(false),
                      );
                    }
                  }}
                />
                {showNonResult && (
                  <p style={{ color: "blue" }}>Thông tin không tồn tại</p>
                )}
              </Col>

              <Col sm="12" md="4">
                <div style={{ display: "inline-block" }}>
                  <strong>Chọn ngày sinh/ rửa tội: </strong>
                  <Input
                    style={{ width: "10rem", marginLeft: "1rem" }}
                    type="date"
                    onChange={(e) => setFilterDate(e.target.value)}
                  />
                </div>

                <Button
                  color="dark"
                  onClick={() =>
                    queryUser(query, filterDate).then(() => setLoading(false))
                  }
                >
                  Tìm
                </Button>
              </Col>
            </Row>

            <Row>
              <div style={styles}>
                <ScaleLoader
                  color="brown"
                  loading={loading}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            </Row>

            {queryResultList.length > 0 && (
              <Row style={{ marginTop: "2rem" }} sm="12">
                <table
                  className="table table-bordered table-hover"
                  id="query-results"
                >
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Mã thiếu nhi</th>
                      <th>Tên Thánh</th>
                      <th>Họ, tên đệm</th>
                      <th>Tên</th>
                      <th>Lớp</th>
                      <th>Ngày sinh</th>
                      <th>Ngày rửa tội</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {queryResultList.map((student, index) => (
                      <tr key={index} id={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to={{
                              pathname:
                                `/user/details/` + student.user_id_result,
                            }}
                            target="_blank"
                          >
                            &nbsp;{student.user_id_result}
                          </Link>
                        </td>
                        <td>{student.holly_name_result}</td>
                        <td>{student.last_name_result}</td>
                        <td>{student.first_name_result}</td>
                        <td>{student.class_id_result}</td>
                        <td>{formatDate(student.birthday_result)}</td>
                        <td>{formatDate(student.christen_date_result)}</td>
                        <td style={{ textAlign: "center" }}>
                          <BiAddToQueue
                            style={{ cursor: "pointer" }}
                            onClick={() => addToLeaveList(student)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Row>
            )}

            {listUserToLeave.length > 0 && (
              <Row style={{ marginTop: "2rem" }} sm="12">
                <h3>DANH SÁCH NGHỈ PHÉP</h3>
                <table
                  className="table table-bordered table-hover"
                  id="print-table"
                >
                  <thead>
                    <tr>
                      <th>STT</th>
                      <th>Mã thiếu nhi</th>
                      <th>Tên Thánh</th>
                      <th>Họ, tên đệm</th>
                      <th>Tên</th>
                      <th>Lớp</th>
                      <th>Ngày sinh</th>
                      <th>Ngày rửa tội</th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {listUserToLeave.map((student, index) => (
                      <tr key={index} id={index}>
                        <td>{index + 1}</td>
                        <td>
                          <Link
                            to={{ pathname: `/user/details/` + student.userId }}
                            target="_blank"
                          >
                            &nbsp;{student.userId}
                          </Link>
                        </td>
                        <td>{student.hollyName}</td>
                        <td>{student.lastName}</td>
                        <td>{student.firstName}</td>
                        <td>{student.classId}</td>
                        <td>{formatDate(student.birthday)}</td>
                        <td>{formatDate(student.christenDate)}</td>
                        <td style={{ textAlign: "center" }}>
                          <AiFillDelete
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              removeFromPrintList(student.userId.trim())
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Row>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={addLeave}>
            OK
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LeaveModal;
