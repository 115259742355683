import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { endpointPublic, getBinaryValue } from "../../components/HttpUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineQrcode } from "react-icons/ai";
import { Buffer } from "buffer";
import { removeAccents } from "../../components/Helper";

toast.configure();
const QRCodeGeneration = (props) => {
  const { className, title, color, userId, hollyName, firstName, lastName } =
    props;

  const [modal, setModal] = useState(false);
  const [userIdModal] = useState(userId);
  const [imageExisted, setImageExisted] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const createQrCode = (e) => {
    e.preventDefault();
    getQrCode();
  };

  const getQrCode = () => {
    getBinaryValue(endpointPublic + "/generate-qr-code-image/" + userIdModal)
      .then((response) => {
        if (response.status === 200) {
          setImageExisted(true);
          let base64ImageString = Buffer.from(response.data, "binary").toString(
            "base64",
          );
          let srcValue = "data:image/png;base64," + base64ImageString;
          document.getElementById("qr-image").src = srcValue;
        }
      })
      .catch((error) => console.log("Creating user's QR Code: " + error));
  };

  const downloadDirectWithBackendWhenErrorOccured = (error) => {
    console.log("Downloading user's QR Code error: " + error);
    window.open(
      endpointPublic + "/download-qr-code-image/" + userIdModal,
      "_blank",
    );
  };

  async function toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }

  async function download(url) {
    let fileName = removeAccents(
      `${userIdModal}_${hollyName.replace(" ", "_")}_${lastName.replace(" ", "_")}_${firstName}.png`,
    );
    const a = document.createElement("a");
    a.href = await toDataURL(url);
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleDownloadClicked = () => {
    try {
      download(endpointPublic + "/download-qr-code-image/" + userIdModal);
    } catch (err) {
      downloadDirectWithBackendWhenErrorOccured(err);
    }
  };

  return (
    <div width="1000px">
      <AiOutlineQrcode color={color} onClick={toggle} />
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => createQrCode(e)}>
            <FormGroup>
              <div>
                <Label for="userId">ID</Label>
                <Input
                  style={{ width: "20rem" }}
                  type="text"
                  name="userId"
                  value={userId}
                  required
                  id="userId"
                  placeholder="Nhập mã thiếu nhi"
                />
              </div>
              <span style={{ color: "green" }}>
                {hollyName + " " + lastName + " " + firstName}
              </span>
            </FormGroup>

            <FormGroup>
              <img id="qr-image" src="" alt="Loading ..." />
            </FormGroup>

            <FormGroup>
              {imageExisted && modal && (
                <button onClick={() => handleDownloadClicked()}>Tải về?</button>
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={createQrCode}>
            Hiện thị QR Code
          </Button>
          <Button color="secondary" onClick={toggle}>
            Huỷ
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default QRCodeGeneration;
