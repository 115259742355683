import React, { useState, useEffect } from "react";
import { Row } from "reactstrap";
import { getWithAuth, endpointUser } from "../HttpUtils";

const AttendedClasses = ({ userId }) => {
  const [attendedClassList, setAttendedClassList] = useState([]);

  useEffect(() => {
    fetchAttendedClasses();
  }, []);

  const fetchAttendedClasses = () => {
    getWithAuth(endpointUser + "/" + userId + "/attended-classes/")
      .then((response) => {
        if (response.status === 200) {
          response.data.sort((classLastYear, currentClass) =>
            currentClass.academicId > classLastYear.academicId ? 1 : -1,
          );
          setAttendedClassList(response.data);
          console.log("Attended class: " + response.data);
        }
      })
      .catch((error) =>
        console.log("Fetching user attended classes error: " + error),
      );
  };

  return (
    <div>
      <Row>
        {attendedClassList.length > 0 && (
          <div className="table-responsive">
            <h4>CÁC LỚP ĐÃ THAM GIA</h4>
            <table className="table table-bordered" id="attended-classes">
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Năm học</th>
                  <th>Mã lớp</th>
                  <th>Tên lớp</th>
                  <th>Điểm miệng HK1</th>
                  <th>Điểm 15' HK1</th>
                  <th>Điểm GK1</th>
                  <th>Điểm CK1</th>
                  <th>Điểm miệng HK2</th>
                  <th>Điểm 15' HK2</th>
                  <th>Điểm GK2</th>
                  <th>Điểm CK2</th>
                </tr>
              </thead>

              <tbody>
                {attendedClassList.map((info, index) => (
                  <tr key={info.classId}>
                    <td>{index + 1}</td>
                    <td>{info.academicId}</td>
                    <td>{info.classId}</td>
                    <td>{info.className}</td>
                    <td>{info.speakingScoreSm1}</td>
                    <td>{info.writingScoreSm1}</td>
                    <td>{info.midExamScoreSm1}</td>
                    <td>{info.finalExamScoreSm1}</td>
                    <td>{info.speakingScoreSm2}</td>
                    <td>{info.writingScoreSm2}</td>
                    <td>{info.midExamScoreSm2}</td>
                    <td>{info.finalExamScoreSm2}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Row>
    </div>
  );
};

export default AttendedClasses;
