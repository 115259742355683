import React, { Component } from "react";
import {
  getWithAuth,
  deleteWithAuth,
  endpointAdmin,
} from "../../components/HttpUtils";
import { Button, Container, Row, Col } from "reactstrap";
import GradeModal from "./GradeModal";
import { messages } from "../../components/message";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../App.css";

toast.configure();
class GradeManagement extends Component {
  state = { gradeList: [], resultInModal: false };

  getResultInModal = (resultModal) => {
    this.setState({ result: resultModal });
  };

  fetchGrades() {
    getWithAuth(endpointAdmin + "/grades")
      .then((response) => {
        if (response.status === 200) {
          this.setState({ gradeList: response.data });
          console.log("Grades: " + response.data);
        }
      })
      .catch((error) => console.log("Fetching Grades error: " + error));
  }

  componentDidMount() {
    this.fetchGrades();
  }

  deleteGrade(id) {
    if (window.confirm(messages.deleteConfirm)) {
      deleteWithAuth(endpointAdmin + "/grades/" + id)
        .then((response) => {
          if (response.status === 200) {
            toast.success(messages.deleteSuccess, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 1000,
            });
            this.fetchGrades();
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.error(
              messages.deleteFailed + "Không được xóa khối đã có lớp!",
              {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
              },
            );
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          console.log("Delete grade error: " + error);
        });
    } else {
      // Do nothing!
    }
  }

  render() {
    return (
      <Container className="container">
        <Row>
          <h3 className="alert alert-warning" align="center">
            QUẢN LÝ KHỐI
          </h3>
          <Col sm="10" md="10.5"></Col>

          <Col>
            <GradeModal
              buttonLabel="Thêm mới"
              className="insert-button"
              title="Thêm mới thể loại"
              color="success"
              gradeId=""
              gradeName=""
              description=""
              getResultInModal={this.getResultInModal}
              insertable={true}
              external={false}
            >
              Thêm mới
            </GradeModal>
          </Col>
        </Row>

        <Row>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Mã khối</th>
                <th>Tên khối</th>
                <th>Mô tả</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.state.gradeList.map((grade) => (
                <tr key={grade.gradeId}>
                  <td>{grade.gradeId}</td>
                  <td>{grade.name}</td>
                  <td>{grade.description}</td>
                  <td>
                    <GradeModal
                      buttonLabel="Sửa"
                      className="edit"
                      title="Sửa"
                      color="info"
                      gradeId={grade.gradeId}
                      gradeName={grade.name}
                      description={grade.description}
                      getResultInModal={this.getResultInModal}
                      insertable={false}
                      external={false}
                    ></GradeModal>
                  </td>
                  <td>
                    <Button
                      color="danger"
                      onClick={() => this.deleteGrade(grade.gradeId)}
                    >
                      Xóa
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>
      </Container>
    );
  }
}

export default GradeManagement;
