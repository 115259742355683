import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  endpointAdmin,
  postwithAuth,
  getWithAuth,
  endpointGlv,
} from "../../components/HttpUtils";
import { toast } from "react-toastify";
import ScaleLoader from "react-spinners/ScaleLoader";
import { BiCheckCircle } from "react-icons/bi";
import "react-toastify/dist/ReactToastify.css";
import { BiCalendarPlus } from "react-icons/bi";
import { BiAddToQueue } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  formatDate,
  styles,
  convertDateTimeToString,
  removeAccents,
} from "../../components/Helper";

toast.configure();
const AttendanceCreatorModal = (props) => {
  const { buttonLabel, className, title, color } = props;

  const [modal, setModal] = useState(false);
  const [scannedDate, setScannedDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [queryResultList, setQueryResultList] = useState([]);
  const [listUserToAddAttendance, setListUserToAddAttendance] = useState([]);
  const [showNonResult, setShowNonResult] = useState(false);
  const [filterDate, setFilterDate] = useState("");
  const [showLoadingUploadStatus, setShowLoadingUploadStatus] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const addAttendance = async (e) => {
    e.preventDefault();
    setShowLoadingUploadStatus(true);
    let noUploadedRecords = 0;

    try {
      do {
        let attendance = listUserToAddAttendance[noUploadedRecords++];
        if (attendance.uploaded) {
          continue;
        }
        let data = {
          info: attendance.qrInfo,
          scannedDate: convertDateTimeToString(scannedDate),
        };

        await postwithAuth(
          endpointAdmin + "/attendances/" + getSheetName(),
          data,
        ).then((response) => {
          if (response.status === 200) {
            attendance.uploaded = true;
            new Promise((resolve) => setTimeout(resolve, 2000));
          }
        });
      } while (noUploadedRecords < listUserToAddAttendance.length);
    } catch (error) {
      console.log("Create attendance error: " + error);
    } finally {
      setShowLoadingUploadStatus(false);
    }
  };

  const checkDisable = () => {
    return listUserToAddAttendance.length === 0 || getSheetName() === "";
  };

  const getUserQrInfo = (user) => {
    let hollyName = user.holly_name_result.replace(" ", "_");
    let lastName = user.last_name_result.replace(" ", "_");

    return removeAccents(
      `${user.user_id_result}_${hollyName}_${lastName}_${user.first_name_result}`,
    );
  };

  const getSheetName = () => {
    let date = new Date(scannedDate);
    let dayOfWeek = date.getDay();
    let sheetName = "";
    let hour = date.getHours();
    let sMinute = date.getMinutes();
    if (dayOfWeek === 2 || dayOfWeek === 4) {
      // Tuesday or Thursday
      if ((hour === 5 && sMinute >= 0) || (hour === 6 && sMinute === 0)) {
        sheetName = "T3T5";
      } else if (
        (hour === 18 && sMinute >= 0) ||
        (hour === 19 && sMinute === 0)
      ) {
        sheetName = "T3T5";
      }
    } else if (
      dayOfWeek === 1 ||
      dayOfWeek === 3 ||
      dayOfWeek === 5 ||
      dayOfWeek === 6
    ) {
      // Monday, Wednesday, Friday or Saturday
      if ((hour === 5 && sMinute >= 0) || (hour === 6 && sMinute === 0)) {
        sheetName = "KHAC";
      } else if (
        (hour === 18 && sMinute >= 0) ||
        (hour === 19 && sMinute === 0)
      ) {
        sheetName = "KHAC";
      }
    } else if (dayOfWeek === 0) {
      // Sunday
      if (hour === 6 && sMinute >= 0 && sMinute <= 15) {
        sheetName = "LESOM";
      }
      if (hour === 7 || (hour === 8 && sMinute <= 30)) {
        sheetName = "GIAOLY";
      } else if (
        (hour === 15 && sMinute >= 30) ||
        (hour === 16 && sMinute === 0)
      ) {
        sheetName = "GIAOLY";
      } else if (
        (hour === 17 && sMinute >= 0) ||
        (hour === 18 && sMinute === 0)
      ) {
        sheetName = "GIAOLY";
      }
    }
    return sheetName;
  };

  const onSearching = (event) => {
    let query = event.target.value.toLowerCase().trim();
    setQuery(query);
  };

  const queryUser = async (queryVal, dateVal) => {
    if (queryVal.trim() === "" && dateVal === "") return;

    setLoading(true);
    let queryStr = "";
    let params = {};
    if (queryVal.trim() !== "") {
      params["info"] = queryVal.trim();
    }
    if (dateVal !== "") {
      params["date"] = dateVal;
    }

    queryStr = Object.entries(params) // return an array of enumerable string-keyed property key-value pairs.
      .map((arrItem) => arrItem.join("=")) // param1=val1, param2=val2
      .join("&"); // param1=val1&param2=val2

    await getWithAuth(`${endpointGlv}/query-user?${queryStr}`)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length === 0) {
            setShowNonResult(true);
          } else setShowNonResult(false);
          setQueryResultList(response.data);
        }
      })
      .catch((error) => console.log("Query users error: " + error));
  };

  const addToAddAttendanceList = (user) => {
    let info = getUserQrInfo(user);
    const userToAddAttendance = {
      userId: user.user_id_result,
      hollyName: user.holly_name_result,
      firstName: user.first_name_result,
      lastName: user.last_name_result,
      classId: user.class_id_result,
      birthday: user.birthday_result,
      christenDate: user.christen_date_result,
      qrInfo: info,
      uploaded: false,
    };
    let temp = [...listUserToAddAttendance];
    temp.push(userToAddAttendance);
    const key = "userId";
    let uniqueList = [
      ...new Map(temp.map((item) => [item[key], item])).values(),
    ];
    setListUserToAddAttendance(uniqueList);
  };

  const removeFromAddAttendanceList = (val) => {
    let temp = [...listUserToAddAttendance];
    let newList = temp.filter((user) => user.userId !== val);
    setListUserToAddAttendance(newList);
  };

  return (
    <div>
      <div style={{ cursor: "pointer" }} onClick={toggle}>
        <BiCalendarPlus style={{ marginBottom: "0.12rem" }} color={color} />
        <span style={{ marginTop: "0.6rem", color: "brown" }} id="span-create">
          Tạo điểm danh
        </span>
      </div>

      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <Label for="hollyName">Ngày giờ điểm danh</Label>
                <Input
                  type="datetime-local"
                  name="scannedDate"
                  value={scannedDate}
                  id="hollyName"
                  placeholder="Chọn ngày giờ điểm danh"
                  max={new Date().toISOString().substring(0, 16)}
                  onChange={(e) => setScannedDate(e.target.value)}
                />
              </FormGroup>
            </Col>

            <Col>
              <FormGroup>
                <strong>
                  <Label for="status">Loại điểm danh</Label>
                </strong>
                <Input
                  type="text"
                  name="status"
                  id="status"
                  readOnly={true}
                  value={getSheetName()}
                />
              </FormGroup>
            </Col>
          </Row>

          <h5>THÊM DANH SÁCH ĐIỂM DANH</h5>
          <Row>
            <Col sm="12" md="8">
              <strong>
                <Label for="search">Tra cứu</Label>
              </strong>
              <br></br>
              <input
                type="search"
                name="search"
                style={{ width: "100%", height: "2.5rem" }}
                placeholder="Nhập mã thiếu nhi, tên thánh, họ tên thiếu nhi, SĐT phụ huynh, họ tên phụ huynh,..."
                onChange={onSearching}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                    queryUser(e.target.value, filterDate).then(() =>
                      setLoading(false),
                    );
                  }
                }}
              />
              {showNonResult && (
                <p style={{ color: "blue" }}>Thông tin không tồn tại</p>
              )}
            </Col>

            <Col sm="12" md="4">
              <div style={{ display: "inline-block" }}>
                <strong>Chọn ngày sinh/ rửa tội: </strong>
                <Input
                  style={{ width: "10rem", marginLeft: "1rem" }}
                  type="date"
                  onChange={(e) => setFilterDate(e.target.value)}
                />
              </div>

              <Button
                color="dark"
                onClick={() =>
                  queryUser(query, filterDate).then(() => setLoading(false))
                }
              >
                Tìm
              </Button>
            </Col>
          </Row>

          <Row>
            <div style={styles}>
              <ScaleLoader
                color="brown"
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          </Row>

          {queryResultList.length > 0 && (
            <Row style={{ marginTop: "2rem" }} sm="12">
              <table
                className="table table-bordered table-hover"
                id="query-results"
              >
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Mã thiếu nhi</th>
                    <th>Tên Thánh</th>
                    <th>Họ, tên đệm</th>
                    <th>Tên</th>
                    <th>Lớp</th>
                    <th>Ngày sinh</th>
                    <th>Ngày rửa tội</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {queryResultList.map((student, index) => (
                    <tr key={index} id={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={{
                            pathname: `/user/details/` + student.user_id_result,
                          }}
                          target="_blank"
                        >
                          &nbsp;{student.user_id_result}
                        </Link>
                      </td>
                      <td>{student.holly_name_result}</td>
                      <td>{student.last_name_result}</td>
                      <td>{student.first_name_result}</td>
                      <td>{student.class_id_result}</td>
                      <td>{formatDate(student.birthday_result)}</td>
                      <td>{formatDate(student.christen_date_result)}</td>
                      <td style={{ textAlign: "center" }}>
                        <BiAddToQueue
                          style={{ cursor: "pointer" }}
                          onClick={() => addToAddAttendanceList(student)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Row>
          )}

          <hr />
          {listUserToAddAttendance.length > 0 && (
            <Row style={{ marginTop: "2rem" }} sm="12">
              <h3>DANH SÁCH ĐIỂM DANH</h3>
              <table
                className="table table-bordered table-hover"
                id="print-table"
              >
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Mã thiếu nhi</th>
                    <th>Tên Thánh</th>
                    <th>Họ, tên đệm</th>
                    <th>Tên</th>
                    <th>Lớp</th>
                    <th>Ngày sinh</th>
                    <th>Ngày rửa tội</th>
                    <th>Thông tin QR</th>
                    <th>Tải lên?</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {listUserToAddAttendance.map((student, index) => (
                    <tr key={index} id={index}>
                      <td>{index + 1}</td>
                      <td>
                        <Link
                          to={{ pathname: `/user/details/` + student.userId }}
                          target="_blank"
                        >
                          &nbsp;{student.userId}
                        </Link>
                      </td>
                      <td>{student.hollyName}</td>
                      <td>{student.lastName}</td>
                      <td>{student.firstName}</td>
                      <td>{student.classId}</td>
                      <td>{formatDate(student.birthday)}</td>
                      <td>{formatDate(student.christenDate)}</td>
                      <td>{student.qrInfo}</td>
                      {/* <td style={{textAlign: 'center'}}>{student.uploaded ? <IoCloudDoneOutline color='green'/>:<IoCloudUploadOutline color='blue'/>}</td> */}
                      <td style={{ textAlign: "center" }}>
                        <ScaleLoader
                          color="blue"
                          loading={showLoadingUploadStatus && !student.uploaded}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          height={18}
                        />
                        <span style={{ height: 18 }} hidden={!student.uploaded}>
                          <BiCheckCircle color="green" />
                        </span>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <AiFillDelete
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            removeFromAddAttendanceList(student.userId.trim())
                          }
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={checkDisable()}
            onClick={(e) => addAttendance(e)}
          >
            {buttonLabel}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Huỷ
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AttendanceCreatorModal;
