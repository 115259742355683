import React, { useState, useEffect } from "react";
import { Col, Row } from "reactstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ScaleLoader from "react-spinners/ScaleLoader";
import Select from "react-select";
import { formatSyncedDate, styles } from "../../components/Helper";
import { getWithAuth, endpointGlv } from "../../components/HttpUtils";
import { getFileName } from "../../components/Helper";
import "../../App.css";

const AttendanceByScanner = () => {
  const [scannerName, setScannerName] = useState("");
  const [attendanceList, setAttendanceList] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchScannerNames();
  }, []);

  const fetchAttendanceByScanner = (scannerName) => {
    if (scannerName !== "" && scannerName.length > 0) {
      setLoading(true);
      getWithAuth(endpointGlv + "/attendance-by-scanner?name=" + scannerName)
        .then((response) => {
          if (response.status === 200) {
            setAttendanceList(response.data);
            setLoading(false);
          }
        })
        .catch((error) => console.log("Fetching statistic error: " + error));
    }
  };

  const fetchScannerNames = () => {
    getWithAuth(endpointGlv + "/attendances/unique-scanner-names")
      .then((response) => {
        if (response.status === 200) {
          let nameList = response.data;
          let options = [];
          nameList.forEach((element) => {
            const option = { value: element, label: element };
            options.push(option);
          });
          setOptions(options);
        }
      })
      .catch((error) => console.log("Fetching scanner names error: " + error));
  };

  const onSearching = (name) => {
    setScannerName(name);
    fetchAttendanceByScanner(name);
  };

  const onItemClick = (id) => {
    window.open(`/attendances/detail/${id}`, "_blank");
  };

  const onOptionChange = (option) => {
    const scannerName = option.value;
    onSearching(scannerName);
  };

  return (
    <div>
      <Row>
        <Col>
          <h5>Chọn hoặc nhập GLV: </h5>
          <Select
            defaultValue={scannerName}
            onChange={onOptionChange}
            options={options}
            placeholder="Nhập tên hiển thị email của người quét"
          />
        </Col>

        <Col>
          {attendanceList.length > 0 && scannerName.length > 0 && (
            <p style={{ float: "right" }}>
              <u>
                Tìm được: <strong>{attendanceList.length}</strong> kết quả
              </u>
            </p>
          )}
        </Col>
      </Row>

      <Row>
        <div style={styles}>
          <ScaleLoader
            color="brown"
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </Row>

      {attendanceList.length > 0 && (
        <div className="table-responsive">
          <table
            style={{ marginTop: "2rem" }}
            className="table table-hover table-bordered"
            id="attendance-checking"
          >
            <thead>
              <tr>
                <th>STT</th>
                <th>Thông tin quét</th>
                <th>Người quét</th>
                <th>Ngày quét</th>
                <th>Ngày đồng bộ</th>
              </tr>
            </thead>
            <tbody>
              {attendanceList.map((attendance, index) => (
                <tr
                  onClick={() => onItemClick(attendance.info.substring(0, 5))}
                  key={index}
                  style={{ cursor: "pointer" }}
                >
                  <td>{index + 1}</td>
                  <td>&nbsp;{attendance.info}</td>
                  <td>{attendance.scannedBy}</td>
                  <td>{attendance.scannedDate}</td>
                  <td>{formatSyncedDate(attendance.syncedDate)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {attendanceList.length > 0 ? (
        <ReactHTMLTableToExcel
          id="attendance-checking"
          className="download-table-xls-button"
          table="attendance-checking"
          filename={getFileName()}
          sheet={scannerName}
          buttonText="Xuất File Excel"
        />
      ) : undefined}
    </div>
  );
};

export default AttendanceByScanner;
