import React from "react";
import { Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeaveListWithType from "./leaveListByType";

toast.configure();

const LeaveManagement = () => {
  return (
    <Container>
      <Row>
        <h3 className="alert alert-warning" align="center">
          QUẢN LÝ NGHỈ PHÉP
        </h3>
      </Row>

      <Row>
        <LeaveListWithType />
      </Row>
    </Container>
  );
};

export default LeaveManagement;
