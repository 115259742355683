import React, { Component } from 'react';
import { deleteWithAuth, endpointAdmin, endpointPublic, get, hostFrontend } from '../../components/HttpUtils';
import { Container, Row, Col } from 'reactstrap';
import { messages } from '../../components/message';
import { toast } from 'react-toastify';
import {RiCloseCircleLine} from 'react-icons/ri';
import {BsListTask} from 'react-icons/bs';
import 'react-toastify/dist/ReactToastify.css';
import ClassModal from './ClassModal';
import '../../App.css';
import './style.css';

toast.configure();
class ClassManagement extends Component {
    state = { currentClassList: [], resultInModal: false}

    getResultInModal = (resultModal) => {
        this.setState({ result: resultModal })
    }

    fetchCurrentClasses() {
        get(endpointPublic + "/classes/current").then((response) => {
            if (response.status === 200) {
                this.setState({ currentClassList: response.data })
            }
        }).catch((error) => console.log("Fetching current classes error: " + error))
    }

    componentDidMount() {
        this.fetchCurrentClasses();
    }

    forwardToStudentList(classId){
        window.location.replace(hostFrontend + "admin/classes/" + classId);
    }

    deleteClass(id) {
        if (window.confirm(messages.deleteConfirm)) {
            deleteWithAuth(endpointAdmin + "/classes/" + id).then((response) => {
                if (response.status === 200) {
                    toast.success(messages.deleteSuccess, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                    });
                    this.fetchCurrentClasses();
                }
            }).catch(error => {
                console.log("Delete class error: " + error);
                toast.error(messages.deleteFailed + "Không được xóa lớp đã có thiếu nhi!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1500,
                });
            })
        } else {
            // Do nothing!
        }
    }

    render() {
        return (
            <Container>
                <Row>
                    <h3 className="alert alert-warning" align="center">QUẢN LÝ LỚP</h3>
                    <Col sm="10" md="10">
                        <p><u>Tổng số lớp: </u>{this.state.currentClassList.length}</p>
                    </Col>

                    <Col>
                        <ClassModal style={{ marginTop: "1rem"}}
                                buttonLabel="Thêm mới lớp học"
                                className="modal-content"
                                title="Thêm mới lớp học"
                                color="success"
                                classId=""
                                insertable={true}>
                                Thêm mới lớp học</ClassModal>
                    </Col>
                </Row>

                <div className='table-responsive'>
                    <table className="table table-hover table-light table-bordered">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Mã lớp</th>
                                <th>Tên lớp</th>
                                <th>Địa điểm</th>
                                <th>GLV</th>
                                <th>Trợ giảng</th>
                                <th>Mã khối</th>
                                <th>Năm học</th>

                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.currentClassList.map((currentClass, index) => (
                                <tr key={currentClass.classId}>
                                    <td>{index+1}</td>
                                    <td>{currentClass.classId}</td>
                                    <td>{currentClass.name}</td>
                                    <td>{currentClass.room}</td>
                                    <td>{currentClass.mainTeacher}</td>
                                    <td>{currentClass.subTeacher}</td>
                                    <td>{currentClass.gradeId}</td>
                                    <td>{currentClass.academicYearId}</td>

                                    <td>
                                        <BsListTask color='brown'/>
                                        <a href={hostFrontend + "admin/classes/" + currentClass.classId}>
                                            <span>DANH SÁCH LỚP</span>
                                        </a>
                                    </td>
                                    <td>
                                        <ClassModal style={{ marginTop: "1rem"}}
                                            buttonLabel="Cập nhật lớp học"
                                            className="modal-content"
                                            title="Cập nhật lớp học"
                                            color="warning"
                                            classId={currentClass.classId}
                                            insertable={false}>
                                            Chỉnh sửa lớp học
                                        </ClassModal>
                                    </td>
                                    <td>
                                        <RiCloseCircleLine color="red" style={{cursor: 'pointer'}} 
                                                onClick={() => this.deleteClass(currentClass.classId)}/>    
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Container>
        );
    }
}

export default ClassManagement;