import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import {
  endpointAdmin,
  endpointPublic,
  get,
  postwithAuth,
  putWithAuth,
} from "../../components/HttpUtils";
import { toast } from "react-toastify";
import { FaPen } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import { messages } from "../../components/message";

toast.configure();
const ClassModal = (props) => {
  const { buttonLabel, className, title, color, classId, insertable } = props;

  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [aClass, setAClass] = useState({});
  const [grades, setGrades] = useState([]);
  const [academicYear, setAcademicYear] = useState({});

  const toggle = () => setModal(!modal);

  useEffect(() => {
    if (classId && classId !== "") getClass(classId);
    getAllGrades();
    getCurrentAcademicYear();
  }, [modal]);

  const getClass = (classId) => {
    get(endpointPublic + "/classes/" + classId)
      .then((response) => {
        if (response.status === 200) {
          setAClass(response.data);
        }
      })
      .catch((error) => {
        console.log("error getting class: " + error);
      });
  };

  const getAllGrades = () => {
    get(endpointPublic + "/grades")
      .then((response) => {
        if (response.status === 200) {
          setGrades(response.data);
        }
      })
      .catch((error) => {
        console.log("error getting class: " + error);
      });
  };

  const getCurrentAcademicYear = () => {
    get(endpointPublic + "/active-academic-year")
      .then((response) => {
        if (response.status === 200) {
          setAcademicYear(response.data);
          if (!aClass.academicYearId || aClass.academicYearId === "")
            setClassAttr("academicYearId", response.data.academicYearId);
        }
      })
      .catch((error) =>
        console.log("Fetching current classes error: " + error),
      );
  };

  const setClassAttr = (key, value) => {
    var tempClass = Object.assign({}, aClass);
    tempClass[key] = value;
    setAClass(tempClass);
  };

  const handleClassSubmitted = () => {
    if (!isFormValid()) return;

    if (insertable) {
      postwithAuth(endpointAdmin + "/classes", aClass)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log("Add a class successfully!");

            toast.success(messages.insertSuccess, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });

            setTimeout(function () {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          console.log("error inserting new class: " + error);
        });
    } else {
      putWithAuth(endpointAdmin + "/classes", aClass)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log("Update a class successfully!");

            toast.success(messages.updateSuccess, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });

            setTimeout(function () {
              window.location.reload();
            }, 2000);
          }
        })
        .catch((error) => {
          console.log("error updating existing class: " + error);
        });
    }
  };

  const isFormValid = () => {
    let errors = {},
      formIsValid = true;
    if (!aClass.classId || aClass.classId === "") {
      errors["classId"] = "Mã lớp không được để trống";
      formIsValid = false;
    }
    if (!aClass.name || aClass.name === "") {
      errors["className"] = "Tên lớp không được để trống";
      formIsValid = false;
    }

    if (!aClass.gradeId || aClass.gradeId === "") {
      errors["gradeId"] = "Vui lòng chọn khối";
      formIsValid = false;
    }
    setErrors(errors);

    return formIsValid;
  };

  return (
    <div>
      {/* <Button color={color} onClick={toggle}>{buttonLabel}</Button> */}
      {insertable ? (
        <Button color={color} onClick={toggle}>
          {buttonLabel}
        </Button>
      ) : (
        <FaPen color={color} onClick={toggle} />
      )}
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader
          toggle={toggle}
        >{`${title} (${academicYear.name})`}</ModalHeader>
        <ModalBody>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <Label for="classId">Mã lớp</Label>
                  <Input
                    value={aClass.classId}
                    name="classId"
                    placeholder="Vd: 24SD2C"
                    required
                    maxLength="40"
                    id="classId"
                    readOnly={!insertable}
                    onChange={(e) =>
                      setClassAttr("classId", e.target.value.toUpperCase())
                    }
                  />
                  <span style={{ color: "red" }}>{errors["classId"]}</span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="className">Tên lớp</Label>
                  <Input
                    type="text"
                    name="className"
                    value={aClass.name}
                    maxLength="50"
                    id="className"
                    required
                    placeholder="Vd: SD2C"
                    onChange={(e) =>
                      setClassAttr("name", e.target.value.toUpperCase())
                    }
                  />
                  <span style={{ color: "red" }}>{errors["className"]}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="mainTeacher">GLV đứng lớp</Label>
                  <Input
                    type="text"
                    name="mainTeacher"
                    value={aClass.mainTeacher}
                    id="mainTeacher"
                    placeholder="Vd: Giuse Trịnh Quốc Cường (0123xxxx289)"
                    onChange={(e) =>
                      setClassAttr("mainTeacher", e.target.value)
                    }
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="subTeacher">GLV phụ lớp</Label>
                  <Input
                    type="text"
                    name="subTeacher"
                    value={aClass.subTeacher}
                    maxLength="150"
                    id="subTeacher"
                    placeholder="Vd: Vinhson Trần Đức Hảo (0902xxxxxx45)"
                    onChange={(e) => setClassAttr("subTeacher", e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="room">Phòng học</Label>
                  <Input
                    type="text"
                    name="room"
                    value={aClass.room}
                    maxLength="50"
                    id="room"
                    placeholder="Vd: Cánh gà/ Sân trường/ Nhà xứ,..."
                    onChange={(e) => setClassAttr("room", e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="gradeId">Khối</Label>
                  <Input
                    type="select"
                    name="gradeId"
                    id="gradeId"
                    onChange={(e) => setClassAttr("gradeId", e.target.value)}
                  >
                    <option key={99999} value={""}>
                      ---------------------------------
                    </option>
                    {grades.map((grade, index) => (
                      <option
                        key={index}
                        value={grade.gradeId}
                        selected={aClass.gradeId === grade.gradeId}
                      >
                        {grade.name}
                      </option>
                    ))}
                  </Input>
                  <span style={{ color: "red" }}>{errors["gradeId"]}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <FormGroup>
                <Label for="description">Ghi chú</Label>
                <Input
                  type="text"
                  name="description"
                  value={aClass.description}
                  maxLength={200}
                  id="description"
                  placeholder="Thêm ghi chú (nếu có)"
                  onChange={(e) => setClassAttr("description", e.target.value)}
                />
              </FormGroup>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleClassSubmitted}>
            Lưu
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ClassModal;
