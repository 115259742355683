import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
} from "reactstrap";
import {
  endpointAdmin,
  endpointUser,
  postwithAuth,
  putWithAuth,
  getWithAuth,
} from "../../components/HttpUtils";
import validator from "validator";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPen } from "react-icons/fa";
import ScaleLoader from "react-spinners/ScaleLoader";
import { messages } from "../../components/message";
import "./style.css";

toast.configure();
const ModalForm = (props) => {
  const {
    buttonLabel,
    className,
    title,
    color,
    userId,
    getResultInModal,
    insertable,
    deleted,
    onMergeUser,
  } = props;

  const [uploadImage, setUploadImage] = useState(null);
  const [modal, setModal] = useState(false);
  const [checkedRoles, setCheckedRoles] = useState("");
  const [base64Str, setBase64Str] = useState("");
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    if (deleted === true) {
      toast.info(messages.updateAfterDeleted, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });

      setTimeout(function () {
        window.location.reload();
      }, 2000);
    }
    setModal(!modal);
    if (!modal) fetchUserDetails();
  };
  const roles = [
    { id: 1, name: "Admin" },
    { id: 2, name: "Student" },
    { id: 3, name: "Glv" },
  ];

  const updateOrInsertUser = (e) => {
    e.preventDefault();

    if (validateForm() !== true) return;

    setLoading(true);

    let photo = user.image;
    if (uploadImage !== null) {
      photo = getByteaFromBase64Str(base64Str);
    }

    var newValues = {
      image: photo,
      roleName: checkedRoles,
    };

    var tempUser = Object.assign({}, user);
    tempUser = Object.assign(user, newValues);

    if (insertable) {
      postwithAuth(endpointAdmin + "/users", tempUser)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log("Insert new user successfully!");

            setLoading(false);
            toast.success(messages.insertSuccess, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });

            setTimeout(function () {
              onMergeUser(response.data, false);
              toggle();
            }, 2000);
            setUser({});
            getResultInModal(true);
          }
        })
        .catch((error) => {
          toast.error(
            messages.insertFailed + " UserId hoặc email đã tồn tại!",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            },
          );
          console.log("error inserting new user: " + error);
          getResultInModal(false);
        });
    } else {
      putWithAuth(endpointAdmin + "/users/" + userId, tempUser)
        .then((response) => {
          if (response.status === 200) {
            console.log(messages.updateSuccess);
            setLoading(false);
            toast.success(messages.updateSuccess, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            });

            setTimeout(function () {
              onMergeUser(user, true);
              toggle();
            }, 2000);
            getResultInModal(true);
          }
        })
        .catch((error) => {
          toast.error(
            messages.updateFailed + " userId hoặc email đã tồn tại!",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 2000,
            },
          );
          console.log("error update user: " + error);
          getResultInModal(false);
        });
    }
  };

  const getByteaFromBase64Str = () => {
    if (base64Str !== "") {
      const byteArr = base64Str.split(",");
      return byteArr[1];
    }
  };

  const validateForm = () => {
    let errors = {},
      formIsValid = true;
    if (user === undefined || user === null) {
      errors["general"] = messages.emptyUser;
      formIsValid = false;
    }
    if (user["hollyName"] === undefined) {
      errors["hollyName"] = messages.fullNameLength;
      formIsValid = false;
    } else {
      if (user["hollyName"].length < 2 || user["hollyName"].length > 40) {
        errors["hollyName"] = messages.fullNameLength;
        formIsValid = false;
      }
    }

    if (user["lastName"] === undefined) {
      errors["lastName"] = messages.fullNameLength;
      formIsValid = false;
    } else {
      if (user["lastName"].length < 2 || user["lastName"].length > 40) {
        errors["lastName"] = messages.fullNameLength;
        formIsValid = false;
      }
    }

    if (user["firstName"] === undefined) {
      errors["firstName"] = messages.fullNameLength;
      formIsValid = false;
    } else {
      if (user["firstName"].length < 1 || user["firstName"].length > 40) {
        errors["firstName"] = messages.fullNameLength;
        formIsValid = false;
      }
    }

    if (
      user.dadPhoneNumber !== undefined &&
      user.dadPhoneNumber !== "" &&
      validator.isMobilePhone(user.dadPhoneNumber) === false
    ) {
      errors["dadPhoneNumber"] = messages.invalidPhoneNumberFormat;
      formIsValid = false;
    } else if (
      user.dadPhoneNumber !== undefined &&
      user.dadPhoneNumber !== "" &&
      (user.dadPhoneNumber.length < 8 || user.dadPhoneNumber.length > 14)
    ) {
      errors["dadPhoneNumber"] = messages.phoneNumberLength;
      formIsValid = false;
    }

    if (
      user.momPhoneNumber !== undefined &&
      user.momPhoneNumber !== "" &&
      validator.isMobilePhone(user.momPhoneNumber) === false
    ) {
      errors["momPhoneNumber"] = messages.invalidPhoneNumberFormat;
      formIsValid = false;
    } else if (
      user.momPhoneNumber !== undefined &&
      user.momPhoneNumber !== "" &&
      (user.momPhoneNumber.length < 8 || user.momPhoneNumber.length > 14)
    ) {
      errors["momPhoneNumber"] = messages.phoneNumberLength;
      formIsValid = false;
    }

    if (
      user.birthday === undefined ||
      user.birthday === null ||
      user.birthday === ""
    ) {
      errors["birthday"] = messages.birthdayRequired;
      formIsValid = false;
    }
    setErrors(errors);

    return formIsValid;
  };

  const handleCheckboxChange = (event) => {
    let options = [],
      option;
    for (let i = 0, len = event.target.options.length; i < len; i++) {
      option = event.target.options[i];
      if (option.selected) {
        options.push(option.value);
      }
    }
    setCheckedRoles(options.join());
  };

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];

      setUploadImage(URL.createObjectURL(img));

      getBase64(event.target.files[0], (result) => {
        setBase64Str(result);
      });
    }
  };

  const fetchUserDetails = () => {
    getWithAuth(endpointUser + "/" + userId)
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data);
          setCheckedRoles(response.data.roleName);
        }
      })
      .catch((error) => console.log("Fetching users error: " + error));
  };

  const setUserAttr = (key, value) => {
    var tempUser = Object.assign({}, user);
    tempUser[key] = value;
    setUser(tempUser);
  };

  const formatDate = (dateStr) => {
    if (dateStr !== null && dateStr !== undefined) {
      let dateArea = dateStr.split("T")[0];
      return dateArea;
    }
  };

  return (
    <div>
      {insertable ? (
        <Button color={color} onClick={toggle}>
          {buttonLabel}
        </Button>
      ) : (
        <FaPen color={color} onClick={toggle} />
      )}
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => updateOrInsertUser(e)}>
            <Row>
              <Col>
                <FormGroup>
                  {!insertable ? (
                    <div>
                      <Label for="userId">ID</Label>
                      <Input
                        type="text"
                        name="userId"
                        value={userId}
                        required
                        readOnly={!insertable}
                        id="userId"
                        placeholder="Enter user id"
                      />
                      <br />
                    </div>
                  ) : undefined}
                </FormGroup>
              </Col>

              <Col></Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <span style={{ color: "red" }}>{errors["general"]}</span>
                  <Label for="hollyName">Tên Thánh</Label>
                  <Input
                    type="text"
                    name="hollyName"
                    value={user.hollyName}
                    required
                    id="hollyName"
                    placeholder="Nhập tên thánh"
                    onChange={(e) => setUserAttr("hollyName", e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["hollyName"]}</span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="lastName">Họ & tên lót</Label>
                  <Input
                    type="text"
                    name="lastName"
                    value={user.lastName}
                    required
                    id="lastName"
                    placeholder="Nhập họ và tên lót"
                    onChange={(e) => setUserAttr("lastName", e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["lastName"]}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="firstName">Tên</Label>
                  <Input
                    type="text"
                    name="firstName"
                    value={user.firstName}
                    required
                    id="firstName"
                    placeholder="Nhập tên"
                    onChange={(e) => setUserAttr("firstName", e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["firstName"]}</span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="email">Email</Label>
                  <Input
                    type="email"
                    name="email"
                    value={user.email}
                    id="email"
                    placeholder="Nhập email"
                    onChange={(e) => setUserAttr("email", e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["email"]}</span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="fatherName">Họ tên cha</Label>
                  <Input
                    name="fatherName"
                    value={user.fatherName}
                    id="fatherName"
                    placeholder="Họ tên cha"
                    onChange={(e) => setUserAttr("fatherName", e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["fatherName"]}</span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="dadPhoneNumber">Số điện thoại cha</Label>
                  <Input
                    type="number"
                    name="dadPhoneNumber"
                    value={user.dadPhoneNumber}
                    id="dadPhoneNumber"
                    placeholder="Số điện thoại cha"
                    onChange={(e) =>
                      setUserAttr("dadPhoneNumber", e.target.value)
                    }
                  />
                  <span style={{ color: "red" }}>
                    {errors["dadPhoneNumber"]}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="motherName">Họ tên mẹ</Label>
                  <Input
                    name="motherName"
                    value={user.motherName}
                    id="motherName"
                    placeholder="Họ tên mẹ"
                    onChange={(e) => setUserAttr("motherName", e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["motherName"]}</span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="momPhoneNumber">Số điện thoại mẹ</Label>
                  <Input
                    type="number"
                    name="momPhoneNumber"
                    value={user.momPhoneNumber}
                    id="momPhoneNumber"
                    placeholder="Số điện thoại mẹ"
                    onChange={(e) =>
                      setUserAttr("momPhoneNumber", e.target.value)
                    }
                  />
                  <span style={{ color: "red" }}>
                    {errors["momPhoneNumber"]}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="region">Giáo Họ</Label>
                  <Input
                    type="region"
                    name="region"
                    value={user.region}
                    id="region"
                    placeholder="Nhập giáo họ"
                    onChange={(e) => setUserAttr("region", e.target.value)}
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="genderSelect">Giới tính</Label>
                  <Input
                    type="select"
                    name="gender"
                    id="genderSelect"
                    onChange={(e) => setUserAttr("gender", e.target.value)}
                  >
                    <option
                      key={1}
                      value={true}
                      selected={user.gender === true}
                    >
                      Nam
                    </option>
                    <option
                      key={2}
                      value={false}
                      selected={user.gender === false}
                    >
                      Nữ
                    </option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="roleSelectMulti">Phân quyền</Label>
                  <Input
                    type="select"
                    name="roles"
                    multiple
                    id="roleSelectMulti"
                    onChange={(event) => {
                      handleCheckboxChange(event);
                    }}
                  >
                    {roles.map((role) => (
                      <option
                        key={role.id}
                        selected={checkedRoles.includes(role.name)}
                        value={role.name}
                      >
                        {role.name}
                      </option>
                    ))}
                  </Input>
                  <span style={{ color: "red" }}>{errors["roles"]}</span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="familyCode">Mã gia đình</Label>
                  <Input
                    name="familyCode"
                    value={user.familyCode}
                    id="familyCode"
                    placeholder="Mã gia đình"
                    onChange={(e) => setUserAttr("familyCode", e.target.value)}
                  />
                  <span style={{ color: "red" }}>{errors["familyCode"]}</span>
                </FormGroup>
              </Col>
            </Row>

            <br />
            <Row>
              <Col>
                <FormGroup>
                  <Label for="birthday">Chọn ngày sinh</Label>
                  {!insertable ? (
                    <Input
                      type="date"
                      name="birthday"
                      id="birthday"
                      value={formatDate(user.birthday)}
                      placeholder={user.birthday}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => setUserAttr("birthday", e.target.value)}
                    ></Input>
                  ) : (
                    <Input
                      type="date"
                      name="birthday"
                      id="birthday"
                      onChange={(e) => setUserAttr("birthday", e.target.value)}
                      max={new Date().toISOString().split("T")[0]}
                    ></Input>
                  )}
                  <span style={{ color: "red" }}>{errors["birthday"]}</span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="christenDate">Chọn ngày rửa tội</Label>
                  {!insertable ? (
                    <Input
                      type="date"
                      name="christenDate"
                      id="christenDate"
                      value={formatDate(user.christenDate)}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) =>
                        setUserAttr("christenDate", e.target.value)
                      }
                    ></Input>
                  ) : (
                    <Input
                      type="date"
                      name="christenDate"
                      id="christenDate"
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) =>
                        setUserAttr("christenDate", e.target.value)
                      }
                    ></Input>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="confirmationDate">Chọn ngày thêm sức</Label>
                  {!insertable ? (
                    <Input
                      type="date"
                      name="confirmationDate"
                      id="confirmationDate"
                      value={formatDate(user.confirmationDate)}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) =>
                        setUserAttr("confirmationDate", e.target.value)
                      }
                    ></Input>
                  ) : (
                    <Input
                      type="date"
                      name="confirmationDate"
                      id="confirmationDate"
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) =>
                        setUserAttr("confirmationDate", e.target.value)
                      }
                    ></Input>
                  )}
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for="startDate">Chọn ngày nhập học</Label>
                  {!insertable ? (
                    <Input
                      type="date"
                      name="startDate"
                      id="startDate"
                      value={formatDate(user.startDate)}
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => setUserAttr("startDate", e.target.value)}
                    ></Input>
                  ) : (
                    <Input
                      type="date"
                      name="startDate"
                      id="startDate"
                      max={new Date().toISOString().split("T")[0]}
                      onChange={(e) => setUserAttr("startDate", e.target.value)}
                    ></Input>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label for="endDate">Chọn ngày tốt nghiệp</Label>
                  {!insertable ? (
                    <Input
                      type="date"
                      name="endDate"
                      id="endDate"
                      value={formatDate(user.endDate)}
                      onChange={(e) => setUserAttr("endDate", e.target.value)}
                    ></Input>
                  ) : (
                    <Input
                      type="date"
                      name="endDate"
                      id="endDate"
                      onChange={(e) => setUserAttr("endDate", e.target.value)}
                    ></Input>
                  )}
                </FormGroup>
              </Col>

              <Col></Col>
            </Row>

            <br />
            <FormGroup>
              <Label for="address">Địa chỉ</Label>
              <Input
                style={{ width: "100%" }}
                type="address"
                name="address"
                value={user.address}
                id="address"
                placeholder="Nhập địa chỉ"
                onChange={(e) => setUserAttr("address", e.target.value)}
              />
            </FormGroup>

            <br />
            <FormGroup>
              <Row>
                <Col sm="3" md="3">
                  <Label for="notes">Ghi chú</Label>
                </Col>
                <Col>
                  <textarea
                    name="notes"
                    type=""
                    style={{ width: "80%" }}
                    value={user.notes}
                    id="notes"
                    placeholder="Ghi chú"
                    onChange={(e) => setUserAttr("notes", e.target.value)}
                  />
                </Col>
              </Row>

              <span style={{ color: "red" }}>{errors["notes"]}</span>
              <br></br>
            </FormGroup>

            <FormGroup>
              <Label for="photoFile">Ảnh</Label>
              <Input
                type="file"
                name="photo"
                id="photoFile"
                accept="image/*"
                onChange={(e) => onImageChange(e)}
              />
              <FormText color="muted">Upload ảnh</FormText>
              {uploadImage !== null ? (
                <img
                  src={uploadImage}
                  style={{ objectFit: "contain" }}
                  width="200"
                  height="200"
                  alt=""
                />
              ) : (
                <img
                  src={`data:image/jpeg;base64,${user.image}`}
                  style={{ objectFit: "contain" }}
                  width="200"
                  height="200"
                  alt=""
                />
              )}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <ScaleLoader
            color="brown"
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
            height={18}
          />
          <Button id="btn-control" color="primary" onClick={updateOrInsertUser}>
            Lưu
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalForm;
