import React, {useState} from "react";
import { Col, CustomInput, Input, Row, Label, Button } from "reactstrap";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { getWithAuth, endpointUser } from "../../components/HttpUtils";
import { addDateStrRepresentation, getFileName } from "../../components/Helper";
import { Link } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";
import '../../App.css';

const AttendanceByUserId = () => {
    const [userId, setUserId] = useState('');
    const [historyChecked, setHistoryChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [fullColumnChecked, setFullColumnChecked] = useState(false);
    const [userAbsent, setUserAbsent] = useState({});
    const [userAttendance, setUserAttendance] = useState();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [errors, setErrors] = useState({});
    const [allowedGiaoLyLeaveDates, setAllowedGiaolyLeaveDates] = useState([]);
    const [allowedLeSomLeaveDates, setAllowedLeSomLeaveDates] = useState([]);

    const styles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const validateForm = (userId, startDate) => {
        let errors = {}, formIsValid = true;
        if (userId === '' || userId === undefined) {
            errors['userId'] = 'Vui lòng nhập mã thiếu nhi';
            formIsValid = false;
        }
        if (startDate === '' || startDate === undefined) {
            errors['startDate'] = 'Vui lòng chọn ngày bắt đầu';
            formIsValid = false;
        }
        setErrors(errors);

        return formIsValid;
    }

    const fetchAttendanceByUserId = async (id, startDate) => {
        if(id !== "" && startDate !== ""){
            setLoading(true);
            await getWithAuth(`${endpointUser}/statistic/${id}?startDate=${startDate}&endDate=${endDate}`).then((response) => {
                if (response.status === 200) {
                    setUserAttendance(response.data);
                }
            }).catch((error) => console.log("Fetching statistic error: " + error))
        }
    }

    const fetchAbsentByUserId = async (id, startDate) => {
        if(id !== "" && startDate !== ""){
            await getWithAuth(`${endpointUser}/statistic-absent-for-user/${id}?startDate=${startDate}&endDate=${endDate}`).then((response) => {
                if (response.status === 200) {
                    setUserAbsent(response.data);
                    let absentGiaoLyDates = response.data.absentGiaoLy;
                    let absentLeSomDates = response.data.absentLeSom;
                    setAllowedGiaolyLeaveDates(absentGiaoLyDates.filter(day => day.includes("(P)")));
                    setAllowedLeSomLeaveDates(absentLeSomDates.filter(day => day.includes("(P)")));
                }
            }).catch((error) => console.log("Fetching statistic error: " + JSON.stringify(error)))
        }
    }

    const onSearching = (id, startDate) => {
        let userId = id.trim();
        setUserId(userId);
        if(!validateForm(userId, startDate)){
            return;
        }
        if(userId.length !== 5){
            return;
        }
        else{
            fetchAbsentByUserId(userId, startDate)
                .then(() => fetchAttendanceByUserId(userId, startDate).then(() => setLoading(false)));
        }
    }

    const handleHistoryCheckboxChanged = (event) => {
        setHistoryChecked(event.target.checked);
    }

    const handleHFullColumnCheckboxChanged = (event) => {
        setFullColumnChecked(event.target.checked);
    }

    const processLeaveInfoDetails = (info) =>{
        let details = info.split("\n")
        if(details.length > 1){
            return <p>{details[0] + " - "} <b>{details[1]}</b></p>
        }
        else
            return <p>{details[0]}</p>
    }

    const renderTableData = (userAttendance, userAbsent) =>{
        if(historyChecked){
            return(
                <tr>
                    <td>
                        <Link to={{ pathname: `/attendances/detail/` + userAttendance.userId }} target="_blank">&nbsp;{userAttendance.userId}</Link>
                    </td>
                    <td>{userAttendance.hollyName}</td>
                    <td>{userAttendance.lastName}</td>
                    <td>{userAttendance.firstName}</td>
                    <td>
                        <ul>
                            {userAttendance.historyT3T5.map((date) => (<li>{addDateStrRepresentation(date)}</li>))}
                        </ul>
                    </td>
                    <td>
                        <ul>
                            {userAttendance.historyKhac.map((date) => (<li>{addDateStrRepresentation(date)}</li>))}
                        </ul>
                    </td>
                    <td>
                        <ul>
                            {userAbsent.absentGiaoLy.map((date) => (<li>{(date)}</li>))}
                        </ul>
                    </td>
                    <td>
                        <ul>
                            {userAbsent.absentLeSomNotSameWithAbsentGiaoLyDates.map((date) => (<li>{(date)}</li>))}
                        </ul>
                    </td>
                    {fullColumnChecked && 
                        <td>
                            <ul>
                                {userAbsent.absentLeSom.map((date) => (<li>{(date)}</li>))}
                            </ul>
                        </td>
                    }
                    {fullColumnChecked && 
                        <td>
                            <ul>
                                {userAttendance.historyGiaoLy.map((date) => (<li>{date}</li>))}
                            </ul>
                        </td>
                    }
                    {fullColumnChecked && 
                        <td>
                            <ul>
                                {userAttendance.historyLeSom.map((date) => (<li>{date}</li>))}
                            </ul>
                        </td>
                    }
                    <td>
                        {processLeaveInfoDetails(userAttendance.notes)}
                    </td>
                </tr>
            );
        }
        else{
            return(
                <tr>
                    <td>
                        <Link to={{ pathname: `/attendances/detail/` + userAttendance.userId }} target="_blank">&nbsp;{userAttendance.userId}</Link>
                    </td>
                    <td>{userAttendance.hollyName}</td>
                    <td>{userAttendance.lastName}</td>
                    <td>{userAttendance.firstName}</td>
                    <td>{userAttendance.historyT3T5 && userAttendance.historyT3T5.length}</td>
                    <td>{userAttendance.historyKhac && userAttendance.historyKhac.length}</td>
                    <td>{userAbsent && 
                        <>
                            <li>VP: <b>{allowedGiaoLyLeaveDates.length}</b></li>
                            <li>KP: <b>{userAbsent.absentGiaoLy.length - allowedGiaoLyLeaveDates.length}</b></li>
                        </>}
                    </td>
                    <td>{userAbsent && 
                        <>
                            <li>VP: <b>{allowedLeSomLeaveDates.length}</b></li>
                            <li>KP: <b>{userAbsent.absentLeSomNotSameWithAbsentGiaoLyDates.length - allowedLeSomLeaveDates.length}</b></li>
                        </>}
                    </td>

                    {fullColumnChecked && 
                        <td>{userAbsent && 
                            <>
                                <li>VP: <b>{allowedLeSomLeaveDates.length}</b></li>
                                <li>KP: <b>{userAbsent.absentLeSom.length - allowedLeSomLeaveDates.length}</b></li>
                                </>}
                        </td>}
                    {fullColumnChecked && <td>{userAttendance.historyGiaoLy.length}</td>}
                    {fullColumnChecked && <td>{userAttendance.historyLeSom.length}</td>}
                    
                    <td>
                        {processLeaveInfoDetails(userAttendance.notes)}
                    </td>
                </tr>
            );
        }
    }

    return (
        <div>
            <Row>
                <Col>
                    <Label for="input-id"><b>Mã thiếu nhi:</b></Label>
                    <Input style={{ width: "20rem" }} type="text" id="input-id"
                        placeholder="Nhập mã thiếu nhi"
                        value={userId}
                        onChange={e => setUserId(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                            e.preventDefault();
                            onSearching(e.target.value, startDate)
                            }
                        }}
                    />
                    <span style={{ color: "red" }}>{errors["userId"]}</span>
                </Col>

                <Col>
                    <Label for="start-date"><b>Ngày bắt đầu:</b></Label>
                    <Input type="date"name="start-date" lang="vn-CA"
                        value={startDate} max={new Date().toISOString().split('T')[0]} 
                        placeholder="Nhập ngày bắt đầu"
                        onChange={e => setStartDate(e.target.value)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                    <span style={{ color: "red" }}>{errors["startDate"]}</span>
                </Col>

                <Col>
                    <Label for="end-date"><b>Ngày kết thúc:</b></Label>
                    <Input type="date"name="end-date"
                        value={endDate} max={new Date().toISOString().split('T')[0]} 
                        placeholder="Nhập ngày kết thúc"
                        onChange={e => setEndDate(e.target.value)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Col>

                <Col>
                    <br/>
                    <CustomInput type="checkbox" id="historyCheckbox" name="historyCheckbox" 
                        defaultChecked={historyChecked} label="Hiển thị lịch sử"
                    checked={historyChecked} onChange={(e) => handleHistoryCheckboxChanged(e)} />
                </Col>

                <Col>
                    <br/>
                    <CustomInput type="checkbox" id="fullColumnCheckBox" name="fullColumnCheckBox" 
                                defaultChecked={fullColumnChecked} label="Hiển thị đủ cột"
                                checked={fullColumnChecked} onChange={(e) => handleHFullColumnCheckboxChanged(e)} />
                </Col>

                <Col>
                    <br/>
                    <Button color="dark" onClick={() => onSearching(userId, startDate)}>
                        Lọc
                    </Button>
                </Col>
            </Row>

            <Row>
                <div style={styles}>
                <ScaleLoader
                    color="brown"
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>
            </Row>
            
            {userAttendance !== undefined && 
                <div className='table-responsive'>
                    <table style={{marginTop: '1rem'}} className="table table-hover table-bordered" id="attendance-checking">
                    <caption style={{captionSide: "bottom", textAlign: "left"}}><strong><u>*Chú Thích:</u> VP (Vắng Phép) - KP (Vắng Không Phép)</strong></caption>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Tên Thánh</th>
                                <th>Họ và tên đệm</th>
                                <th>Tên</th>
                                <th><b>DỰ</b> lễ thứ 3, 5 (ngày)</th>
                                <th><b>DỰ</b> lễ thứ 2,4,6 (ngày)</th>
                                <th><b>Vắng</b> học giáo lý (ngày)</th>
                                <th><b>Vắng</b> đi lễ sớm (ngày)</th>
                                {fullColumnChecked && <th><b>Vắng</b> đi lễ sớm toàn bộ(ngày)</th>}
                                {fullColumnChecked && <th>Học giáo lý (ngày)</th>}
                                {fullColumnChecked && <th>Đi lễ sớm (ngày)</th>}
                                <th>Ghi chú</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableData(userAttendance, userAbsent)}
                        </tbody>
                    </table>
                </div>
            }

            <br/>
            {userAttendance !== undefined ? 
                <div style={{float: 'right'}}>
                    <ReactHTMLTableToExcel
                        id="attendance-checking"
                        className="download-table-xls-button"
                        table="attendance-checking"
                        filename={getFileName()}
                        sheet={userId}
                        buttonText="Xuất File Excel"/>
                </div> : undefined}
        </div>
    );
}

export default AttendanceByUserId;