export function formatDate(dateVal) {
  if (dateVal !== null && dateVal !== undefined) {
    var newDate = new Date(dateVal);
    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = newDate.getHours();
    var iHourCheck = parseInt(sHour);

    if (iHourCheck > 12) {
      sHour = iHourCheck - 12;
    } else if (iHourCheck === 0) {
      sHour = "12";
    }
    sHour = padValue(sHour);
    return sDay + "-" + sMonth + "-" + sYear;
  } else return "";
}

export function formatSyncedDate(dateVal) {
  if (dateVal !== null && dateVal !== undefined) {
    let date = dateVal.split(" ")[0];
    let time = dateVal.split(" ")[1];
    let month = date.split("/")[0];
    let day = date.split("/")[1];
    let year = date.split("/")[2];

    return `${day}-${month}-${year} ${time}`;
  } else return "";
}

function padValue(value) {
  return value < 10 ? "0" + value : value;
}

export const getFileName = () => {
  return `THIEU_NHI_EXPORT_${new Date().toISOString()}`;
};

export function removeAccents(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/đ/g, "d")
    .replace(/Đ/g, "D");
}

export function checkUserLogged() {
  let userId = localStorage.getItem("userId");
  let token = localStorage.getItem("token");

  if (
    userId !== undefined &&
    userId !== null &&
    token !== undefined &&
    token !== null &&
    token !== undefined &&
    token !== null
  )
    return true;

  return false;
}

export function convertDateTimeToString(datetime) {
  if (datetime !== null && datetime !== undefined) {
    var newDate = new Date(datetime);
    var sMonth = padValue(newDate.getMonth() + 1);
    var sDay = padValue(newDate.getDate());
    var sYear = newDate.getFullYear();
    var sHour = padValue(newDate.getHours());
    var sMinute = padValue(newDate.getMinutes());
    var second = padValue(newDate.getSeconds());

    return (
      sDay +
      "-" +
      sMonth +
      "-" +
      sYear +
      " " +
      sHour +
      ":" +
      sMinute +
      ":" +
      second
    );
  } else return "";
}

export function stringToDate(_date, _format, _delimiter) {
  var formatLowerCase = _format.toLowerCase();
  var formatItems = formatLowerCase.split(_delimiter);
  var dateItems = _date.split(_delimiter);
  var monthIndex = formatItems.indexOf("mm");
  var dayIndex = formatItems.indexOf("dd");
  var yearIndex = formatItems.indexOf("yyyy");
  var month = parseInt(dateItems[monthIndex]);
  month -= 1;
  var formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
  return formatedDate;
}

export function addDateStrRepresentation(dateTimeStr) {
  var dateStr = dateTimeStr.trim().split(" ")[0];
  var date = stringToDate(dateStr, "dd-mm-yyyy", "-");
  var dayOfWeek = date.getDay();
  var dayOfWeekStr = "";
  switch (dayOfWeek) {
    case 1: {
      dayOfWeekStr = "T2";
      break;
    }
    case 2: {
      dayOfWeekStr = "T3";
      break;
    }
    case 3: {
      dayOfWeekStr = "T4";
      break;
    }
    case 4: {
      dayOfWeekStr = "T5";
      break;
    }
    case 5: {
      dayOfWeekStr = "T6";
      break;
    }
    case 6: {
      dayOfWeekStr = "T7";
      break;
    }
    case 0: {
      dayOfWeekStr = "CN";
      break;
    }
    default:
      break;
  }
  return `${dayOfWeekStr}, ${dateTimeStr}`;
}

export function formateDateString(dateStr) {
  let values = dateStr.split("-");
  return `${values[2]}-${values[1]}-${values[0]}`;
}

export function getFormattedDate(date) {
  var year = date.getFullYear();

  var month = (1 + date.getMonth()).toString();
  month = month.length > 1 ? month : "0" + month;

  var day = date.getDate().toString();
  day = day.length > 1 ? day : "0" + day;

  return day + "/" + month + "/" + year;
}

export const styles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
