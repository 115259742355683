import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Card from './card';

const CardList = (props) => {
    const {
        userList
    } = props;

    return (
        <>
            {userList.map((user, index) => <Card key={user.userId} userId={user.userId} index={index}></Card>)}
        </>
    );
}

export default CardList;