import React, { useEffect } from "react";
import { Container, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import Tabs from "../Tabs/Tabs";
import InfoDetails from "./InfoDetails";
import AttendedClasses from "./AttendedClasses";
import UserAttendanceDetails from "./UserAttendanceDetails";

const UserDetails = () => {
  const { id } = useParams();

  useEffect(() => {
    document.body.scrollIntoView({ behavior: "smooth" });
  }, []);

  const tabs = [
    {
      id: 1,
      tabTitle: "CÁ NHÂN",
      title: "",
      content: <InfoDetails userId={id} />,
    },
    {
      id: 2,
      tabTitle: "ĐIỂM DANH",
      title: "",
      content: <UserAttendanceDetails userId={id} />,
    },
    {
      id: 3,
      tabTitle: "LỚP HỌC",
      title: "",
      content: <AttendedClasses userId={id} />,
    },
  ];

  return (
    <div style={{ marginBottom: "10rem" }}>
      <Container>
        <Row>
          <Tabs tabs={tabs} mdProp={8}></Tabs>
        </Row>
      </Container>
    </div>
  );
};

export default UserDetails;
