import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import { endpointAdmin, putWithAuth, get, endpointPublic } from '../../components/HttpUtils';
import { toast } from 'react-toastify';
import { AiOutlineSync } from "react-icons/ai";
import ScaleLoader from "react-spinners/ScaleLoader";
import 'react-toastify/dist/ReactToastify.css';
import { messages } from '../../components/message';

toast.configure();
const ChangingClassModal = (props) => {
    const {
        userId,
        fullName,
        oldClassId
    } = props;

    const [modal, setModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [newClassId, setNewClassId] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentClasses, setCurrentClasses] = useState([]);

    const toggle = () => setModal(!modal);

    useEffect(() => {
        getCurrentClasses();
    }, []);

    const changeClass = () => {
        setLoading(true);
        putWithAuth(`${endpointAdmin}/learns/change-class/${userId}/${oldClassId}/${newClassId}`).then((response) => {
            if (response.status === 200) {
                console.log(`Change user ${userId} from class ${oldClassId} to ${newClassId} successfully!`);
                
                toast.success(messages.updateSuccess, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 2000,
                });

                setTimeout(function () {
                    window.location.reload();
                }, 2000);
            }
        })
        .catch(error => {console.log("error changing class: " + error)})
        .finally(() => setLoading(false));
    }

    const handleChangeClassSubmit = () => {
        if(!isFormValid())
            return;

        changeClass();
    }

    const isFormValid = () => {
        let errors = {}, formIsValid = true;
        if (!newClassId || newClassId === '') {
            errors['classId'] = 'Mã lớp không được để trống';
            formIsValid = false;
        }
        
        setErrors(errors);

        return formIsValid;
    }

    const getCurrentClasses = () => {
        get(endpointPublic + "/classes/current").then((response) => {
          if (response.status === 200) {
            setCurrentClasses(response.data);
          }
        }).catch((error) => console.log("Fetching current classes error: " + error))
      }

    return (
        <div>
            <AiOutlineSync style={{cursor: 'pointer'}} onClick={toggle}/>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>CHUYỂN LỚP</ModalHeader>
                <ModalBody>
                    <Form >
                        <Row>
                            <FormGroup>
                                <Label for="userId">Mã Thiếu Nhi</Label>
                                <Input
                                    value={userId} 
                                    name="userId"
                                    id="userId" 
                                    readOnly={true}
                                />
                            </FormGroup>
                        </Row>

                        <Row>
                            <FormGroup>
                                <Label for="fullName">Tên Thánh, Họ Tên</Label>
                                <Input
                                    value={fullName}
                                    name="fullName"
                                    id="fullName-changing-class" 
                                    readOnly={true}
                                />
                            </FormGroup>
                        </Row>

                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="classId">Lớp hiện tại</Label>
                                    <Input
                                        value={oldClassId} name="currentClassId"
                                        required maxLength="40" id="classId" readOnly={true}
                                    />
                                </FormGroup>
                            </Col>

                            <Col>
                                <FormGroup>
                                <Label for="newClassId"><strong>Lớp mới</strong></Label>
                                <Input type="select" name="class-academic-year" onChange={(e)=>setNewClassId(e.target.value)}>
                                    <option>-----------Lớp học----------</option>
                                    {currentClasses
                                        .map((currentClass) => (
                                        <option
                                            key={currentClass.classId}
                                            value={currentClass.classId}
                                        >{currentClass.name}
                                        </option>
                                        ))
                                    }
                                </Input>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row>
                            <span style={{ color: "red" }}>{errors["classId"]}</span>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <ScaleLoader
                        color="brown"
                        loading={loading}
                        aria-label="Loading Spinner"
                        data-testid="loader-class-changing"
                        height={18}
                    />
                    <Button color="primary" disabled={newClassId === oldClassId} onClick={handleChangeClassSubmit}>Lưu</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ChangingClassModal;