import React, { useState, useEffect } from 'react';
import { endpointUser, endpointPublic, getWithAuth, getBinaryValue } from '../../components/HttpUtils';
import 'react-toastify/dist/ReactToastify.css';
import ScaleLoader from "react-spinners/ScaleLoader";
import { getFormattedDate, styles } from '../../components/Helper';
import './style.css'

const Card = (props) => {
    const {
        userId, index
    } = props;

    const [user, setUser] = useState({})
    const [marginLeftConf, setMarginLeftConf] = useState(6)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchUser().then(() => setLoading(false));
        getQrCode();
        if(index % 2 !== 0) setMarginLeftConf(14)        
    }, [userId]);

    const fetchUser = async () => {
        await getWithAuth(endpointUser + "/" + userId).then((response) => {
            if(response.status === 200){
                setUser(response.data);
            }
        }).catch((error) => console.log("Fetching user details error: " + error))
    }

    const getQrCode = () => {
        getBinaryValue(endpointPublic + "/generate-qr-code-image/" + userId).then((response) => {
            if (response.status === 200) {
                let base64ImageString = Buffer.from(response.data, 'binary').toString('base64')
                let srcValue = "data:image/png;base64,"+base64ImageString
                document.getElementById(`qr-image-user-${userId}`).src = srcValue
            }
        }).catch((error) => console.log("Creating user's QR Code: " + error))
    }

    return (
        <div className="col-sm-4" id='card-style' style={{marginLeft: `${marginLeftConf}rem`}}>
            <div className="card">
                <div className="card-header">
                    <strong>THẺ THIẾU NHI - GX THÁNH GIUSE</strong>
                </div>

                <div className='card-body' id='bg-image' style={{display: 'flex', flexDirection:'column', flex:1, backgroundImage:`url(${process.env.PUBLIC_URL+ "/gx-thanh-giuse-overview.jpg"})`}}>
                    <div id='user-info' style={{display:'flex', marginTop: 'auto', justifyContent: 'space-between'}}>
                        <div id='user-image' style={{flexDirection: 'column'}}>
                            <p><strong>{`${user.userId}`}</strong></p>
                            {(user.image !== null) ?
                            <img src={`data:image/jpeg;base64,${user.image}`} height="100" style={{objectFit: 'contain'}} alt=""></img>
                            :
                            <img src={window.location.origin + '/hinh-thieu-nhi-chibi.jpg'} height="100" alt=""></img>
                            }
                            {/* <img src={`data:image/jpeg;base64,${user.image}`} height="100" style={{objectFit: 'contain'}} alt='Chưa có hình'></img> */}
                        </div>

                        <div id='info-details' style={{marginTop: 'auto', padding: 'auto'}}>
                            <div>
                            <div style={styles}>
                                <ScaleLoader
                                    color="brown"
                                    loading={loading}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                />
                            </div>
                                <h5 style={{color:'white', background: 'green', width: 'fit-content', margin: 'auto'}}>{user.hollyName}</h5>
                                <p style={{display:'inline-block', color:'white', background:'black', width: 'fit-content', textAlign: 'center', margin: 'auto'}}><b>{`${user.lastName} ${user.firstName}`}</b></p>
                            </div>
                        </div>

                        <div id='qr-issue-date' style={{display:'flex', flexDirection:'column-reverse'}}>
                            <img id={`qr-image-user-${userId}`} width={100} height={100} style={{objectFit: 'contain'}} alt=""></img>
                            <p style={{fontSize: '9px', color:'black', background:'pink'}}>Ngày cấp: {getFormattedDate(new Date()).trim()}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;