import React from "react";
import { Row, Container } from "reactstrap";
import Tabs from "../../components/Tabs/Tabs";
import CardBySearching from "./cardPrintedBySeaching";
import CardByUserListInClass from "./cardPrintedByClass";
import "../../App.css";

const CardManagement = () => {
  const tabs = [
    {
      id: 1,
      tabTitle: "IN THẺ THEO TÌM KIẾM",
      title: "",
      content: <CardBySearching />,
    },
    {
      id: 2,
      tabTitle: "IN THẺ THEO DANH SÁCH LỚP",
      title: "",
      content: <CardByUserListInClass />,
    },
  ];

  return (
    <Container>
      <Row>
        <h3 className="alert alert-warning" align="center">
          QUẢN LÝ THẺ
        </h3>
      </Row>

      <Row>
        <Tabs tabs={tabs} mdProp={12}></Tabs>
      </Row>
    </Container>
  );
};

export default CardManagement;
