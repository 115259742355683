import React, { useState, useEffect } from "react";
import { Row, Container, Button, Col, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { endpointPublic, get } from "../../components/HttpUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillDelete } from "react-icons/ai";
import CardList from "./cardList";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { styles } from "../../components/Helper";
import ScaleLoader from "react-spinners/ScaleLoader";
import "./style.css";
import "../../App.css";

toast.configure();
const CardByUserListInClass = () => {
  const [loading, setLoading] = useState(false);
  const [classList, setClassList] = useState([]);
  const [filterClass, setFilterClass] = useState("");
  const [studentInClassList, setStudentInClassList] = useState([]);
  const [itemPerPage] = useState(8);
  const [pageNumbers, setPageNumbers] = useState([]);

  useEffect(() => {
    getCurrentClasses().then(setLoading(false));
  }, []);

  const getCurrentClasses = async () => {
    setLoading(true);
    await get(endpointPublic + "/classes/current")
      .then((response) => {
        if (response.status === 200) {
          setClassList(response.data);
        }
      })
      .catch((error) =>
        console.log("Fetching current classes error: " + error),
      );
  };

  const fetchStudents = async (classId) => {
    setLoading(true);
    await get(endpointPublic + "/student-in-class/" + classId)
      .then((response) => {
        if (response.status === 200) {
          setStudentInClassList(response.data);
          let totalPages = Math.ceil(response.data.length / itemPerPage);

          let temp = [];
          for (let index = 0; index < totalPages; index++) {
            temp.push(index);
          }
          console.log("Page numbers: ", temp);
          setPageNumbers(temp);
        }
      })
      .catch((error) =>
        console.log("Fetching student in class error: " + error),
      );
  };

  const handleFilterChange = (event) => {
    setFilterClass(event.target.value);
    fetchStudents(event.target.value).then(() => setLoading(false));
  };

  // const exportSnapshotImage = async () => {
  //     toast.info(`Vui lòng chờ trong giây lát...`, {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000,
  //     });

  //     const inputs = document.getElementsByClassName('cards');
  //     for(let index=0; index<inputs.length; index++){
  //         const canvas = await html2canvas(inputs[index]);
  //         const base64 = canvas.toDataURL();
  //         const a = document.createElement("a");
  //         a.href = base64;
  //         a.download = `The_Thieu_Nhi_Lop_${filterClass}_${index}.png`;
  //         a.click();
  //     }
  // }

  const exportPDFFile = async () => {
    toast.info(`Vui lòng chờ trong giây lát...`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });

    var doc = new jsPDF("p", "mm");
    for (let index = 0; index < pageNumbers.length; index++) {
      let input = document.getElementById(`card-${index}`);
      await html2canvas(input, { scale: 2 }).then((canvas) => {
        var imgData = canvas.toDataURL("image/png");
        var imgWidth = 210;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var position = 10; // give some top padding to first page

        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      });
      if (index < pageNumbers.length - 1) {
        doc.addPage();
      }
    }
    doc.save(`The_Thieu_Nhi_Lop_${filterClass}.pdf`);
  };

  const removeFromPrintList = (val) => {
    let temp = [...studentInClassList];
    let newList = temp.filter((user) => user.userId !== val);
    setStudentInClassList(newList);
  };

  return (
    <Container className="container">
      <Row>
        <Col>
          <Label for="class-academic-year">
            <b>Chọn lớp: </b>
          </Label>
          <Input
            type="select"
            name="class-academic-year"
            style={{ width: "20rem" }}
            onChange={(e) => handleFilterChange(e)}
          >
            <option>---------------Lớp học---------------</option>
            {classList.map((currentClass) => (
              <option key={currentClass.classId} value={currentClass.classId}>
                {currentClass.name}
              </option>
            ))}
          </Input>
        </Col>
      </Row>

      <Row>
        <div style={styles}>
          <ScaleLoader
            color="brown"
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </Row>

      {studentInClassList.length > 0 && (
        <Row style={{ marginTop: "1rem", width: "50%" }}>
          <table
            className="table table-bordered table-hover"
            id="students-in-class"
          >
            <thead>
              <tr>
                <th>STT</th>
                <th>Mã thiếu nhi</th>
                <th>Tên Thánh & Họ, tên</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {studentInClassList.map((student, index) => (
                <tr key={student.userId} id={student.userId}>
                  <td>{index + 1}</td>
                  <td>
                    <Link
                      to={{ pathname: `/user/details/` + student.userId }}
                      target="_blank"
                    >
                      &nbsp;{student.userId}
                    </Link>
                  </td>
                  <td>{student.fullName}</td>
                  <td style={{ textAlign: "center" }}>
                    <AiFillDelete
                      style={{ cursor: "pointer" }}
                      onClick={() => removeFromPrintList(student.userId.trim())}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
        </Row>
      )}

      {pageNumbers.map((num) => (
        <Row className="cards" key={`card-${num}`} id={`card-${num}`}>
          <CardList
            userList={[...studentInClassList].slice(
              itemPerPage * num,
              itemPerPage * (num + 1),
            )}
          />
          <hr style={{ border: "2px solid red" }} />
        </Row>
      ))}

      {studentInClassList.length > 0 && (
        <Row>
          <hr />
          <div style={{ display: "inline-block" }}>
            <Button color="info" onClick={exportPDFFile}>
              XUẤT FILE THẺ
            </Button>

            {/* <Button style={{marginLeft: '0.2rem'}} color="warning" onClick={exportSnapshotImage}>
                        TẠO INFOGRAPHIC
                    </Button> */}
          </div>
        </Row>
      )}
    </Container>
  );
};

export default CardByUserListInClass;
