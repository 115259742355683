import React from 'react';

const Carousel = () => {
    return (
    <div id="carouselExampleDark" class="carousel carousel-light slide" data-bs-ride="carousel" style={{margin: '1.2rem'}}>
        <div className="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
        </div>
        <div className="carousel-inner">
            <div className="carousel-item active" data-bs-interval="2000">
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={window.location.origin + '/slider1.png'} height="500" className="d-block w-100" alt="..."/>
                    <div className="carousel-caption d-none d-md-block" style={{color: 'white'}}>
                        <h5>THÁNG 11</h5>
                        <p>CẦU CHO CÁC ĐẲNG LINH HỒN</p>
                    </div>
                </div>
            </div>
            <div className="carousel-item" data-bs-interval="2000">
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={window.location.origin + '/slider2.jpg'} height="500" className="d-block w-95" alt="..."/>
                    <div className="carousel-caption d-none d-md-block" style={{color: 'red'}}>
                        <h5>QUAN THẦY GIÁO LÝ VIÊN - HUYNH TRƯỞNG</h5>
                        <p>LỄ CÁC THÁNH TỬ ĐẠO VIỆT NAM</p>
                    </div>
                </div>
            </div>
            <div className="carousel-item" data-bs-interval="1500">
                <div className='d-flex justify-content-center align-items-center'>
                    <img src={window.location.origin + '/slider3.jpg'} height="500" className="d-block w-90" alt="..."/>
                    <div className="carousel-caption d-none d-md-block" style={{color: 'orange', backgroundColor: 'white'}}>
                        <h5>CHIẾN DỊCH THI ĐUA MÙA VỌNG</h5>
                        <p>THIẾU NHI THI ĐUA NUÔI HEO ĐẤT</p>
                    </div>
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
    );
}

export default Carousel;