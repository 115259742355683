import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  endpointAdmin,
  hostFrontend,
  putWithAuth,
  getWithAuth,
  endpointUser,
} from "../../components/HttpUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { messages } from "../../components/message";

toast.configure();
const StatusModalForm = (props) => {
  const { buttonLabel, className, title, color, userId, getResultInModal } =
    props;

  const [userIdModal] = useState(userId);
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState({});
  const [statusFilter, setStatusFilter] = useState("ACTIVE");

  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      fetchUserDetails();
      console.log("Fetching");
    }
  };

  const changeStatus = (e) => {
    e.preventDefault();

    putWithAuth(
      endpointAdmin + "/users/change-status/" + userId + "/" + statusFilter,
    )
      .then((response) => {
        if (response.status === 200) {
          console.log(messages.updateSuccess);
          toast.success(messages.updateSuccess, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setTimeout(function () {
            window.location.replace(hostFrontend + "admin/users");
          }, 2000);
          getResultInModal(true);
        }
      })
      .catch((error) => {
        toast.error(messages.updateFailed + " kiểm tra kết nối mạng!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        console.log("error update user: " + error);
        getResultInModal(false);
      });
  };

  const fetchUserDetails = () => {
    getWithAuth(endpointUser + "/" + userIdModal)
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data);
        }
      })
      .catch((error) => console.log("Fetching users error: " + error));
  };

  const handleFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  return (
    <div width="1000px">
      <AiOutlineExclamationCircle color={color} onClick={toggle} />
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => changeStatus(e)}>
            <FormGroup>
              <div>
                <Label for="userId">ID</Label>
                <Input
                  style={{ width: "20rem" }}
                  type="text"
                  name="userId"
                  value={userId}
                  required
                  readOnly={true}
                  id="userId"
                  placeholder="Enter user id"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="hollyName">Tên Thánh</Label>
              <Input
                style={{ width: "20rem" }}
                type="text"
                name="hollyName"
                value={user.hollyName}
                readOnly={true}
                id="hollyName"
                placeholder="Nhập tên thánh"
              />
            </FormGroup>
            <FormGroup>
              <Label for="lastName">Họ & tên lót</Label>
              <Input
                style={{ width: "20rem" }}
                type="text"
                name="lastName"
                value={user.lastName}
                readOnly={true}
                id="lastName"
                placeholder="Nhập họ và tên lót"
              />
            </FormGroup>
            <FormGroup>
              <Label for="firstName">Tên</Label>
              <Input
                style={{ width: "20rem" }}
                type="text"
                name="firstName"
                value={user.firstName}
                readOnly={true}
                id="firstName"
                placeholder="Nhập tên"
              />
            </FormGroup>
            <FormGroup>
              <strong>
                <Label for="status">Tình trạng</Label>
              </strong>
              <Input
                type="select"
                name="status"
                id="status"
                onChange={(e) => handleFilterChange(e)}
              >
                <option
                  key={1}
                  value={"ACTIVE"}
                  selected={"ĐANG HỌC" === user.status}
                >
                  ĐANG HỌC
                </option>
                <option
                  key={2}
                  value={"DISABLE"}
                  selected={"KHÔNG HOẠT ĐỘNG" === user.status}
                >
                  KHÔNG HOẠT ĐỘNG
                </option>
                <option
                  key={3}
                  value={"DROP_OUT"}
                  selected={"NGHỈ HỌC NGANG" === user.status}
                >
                  NGHỈ HỌC NGANG
                </option>
                <option
                  key={4}
                  value={"SWITCHED"}
                  selected={"ĐÃ CHUYỂN XỨ" === user.status}
                >
                  ĐÃ CHUYỂN XỨ
                </option>
                <option
                  key={5}
                  value={"COMPLETED"}
                  selected={"ĐÃ TỐT NGHIỆP" === user.status}
                >
                  ĐÃ TỐT NGHIỆP
                </option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={changeStatus}>
            {buttonLabel}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Huỷ
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default StatusModalForm;
