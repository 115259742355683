import React, { Component } from 'react';
import { deleteWithAuth, endpointAdmin, getWithAuth } from '../../components/HttpUtils';
import { Button, Container, Row, Col, Label, Input } from 'reactstrap';
import AcademicYearModal from './AcademicYearModal';
import './style.css'
import { messages } from '../../components/message';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../App.css'
import { formatDate } from '../../components/Helper';

toast.configure();
class AcademicYearManagement extends Component {
    state = { academicYearList: [], resultInModal: false, enableViewingClasses: false}

    getResultInModal = (resultModal) => {
        this.setState({ result: resultModal })
    }

    fetchAcademicYears() {
        getWithAuth(endpointAdmin + "/academic-years").then((response) => {
            if (response.status === 200) {
                this.setState({ academicYearList: response.data })
            }
        }).catch((error) => console.log("Fetching authors error: " + error))
    }

    componentDidMount() {
        this.fetchAcademicYears();
    }

    deleteBrand(id) {
        if (window.confirm(messages.deleteConfirm)) {
            deleteWithAuth(endpointAdmin + "/academic-years/" + id).then((response) => {
                if (response.status === 200) {
                    toast.success(messages.deleteSuccess, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                    });
                    this.fetchAcademicYears();
                }
            }).catch(error => {
                if (error.response) {
                    toast.error(messages.deleteFailed + "Không được xóa năm học đã có lớp hoặc học kỳ!", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1500,
                    });
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
                console.log("Delete author error: " + error);
            })
        } else {
            // Do nothing!
        }
    }

    render() {
        return (
            <Container className="container">
                <Row>
                    <h3 className="alert alert-warning" align="center">QUẢN LÝ NĂM HỌC</h3>
                    <Col sm="10" md="10"></Col>
                    <Col>
                        <AcademicYearModal
                            style={{marginRight: 0}}
                            buttonLabel="Thêm mới năm học"
                            className="insert-button"
                            title="Thêm mới năm học"
                            color="success"
                            academicYearId=""
                            insertable={true}>
                            Thêm mới năm học</AcademicYearModal>
                    </Col>
                </Row>

                <Row>
                    <Col sm="10" md="10"></Col>
                    <Col>
                        <br/>
                        <Input type="checkbox" name="viewAllClasses" value={this.state.enableViewingClasses}
                            id="viewAllClasses" checked={this.state.enableViewingClasses}
                            onChange={e => this.setState({enableViewingClasses: e.target.checked})} />
                        <Label for="viewAllClasses">Hiển thị lớp?</Label>
                    </Col>
                </Row>

                <div className='table-responsive'>
                    <table className="table table-bordered">
                        <thead style={{textAlign: 'center'}}>
                            <tr>
                                <th>Mã năm học</th>
                                <th>Tên năm học</th>
                                <th>Ngày bắt đầu</th>
                                <th>Ngày kết thúc</th>
                                <th>{this.state.enableViewingClasses ? 'Danh sách lớp' : 'Số lượng lớp'}</th>
                                <th>Trạng thái</th>
                                <th>Mô tả</th>

                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.academicYearList.map((academicYear) => (
                                <tr key={academicYear.academicYearId}>
                                    <td>{academicYear.academicYearId}</td>
                                    <td>{academicYear.name}</td>
                                    <td>{formatDate(academicYear.startDate)}</td>
                                    <td>{formatDate(academicYear.endDate)}</td>
                                    <td>
                                        {this.state.enableViewingClasses ? 
                                        <ol>
                                            {academicYear.classIds.sort().map((id) => (<li key={id}><a href={`/admin/classes/${id}`}>{id}</a></li>))}
                                        </ol>
                                        : academicYear.classIds.length
                                    }
                                    </td>
                                    <td>{academicYear.isCurrent ? "Đang hoạt động" : "Không hoạt động"}</td>
                                    <td>{academicYear.description}</td>

                                    <td><AcademicYearModal
                                        buttonLabel="Sửa"
                                        className="edit"
                                        title="Sửa"
                                        color="info"
                                        academicYearId={academicYear.academicYearId}
                                        insertable={false}
                                        external={false}>
                                    </AcademicYearModal></td>
                                    <td>
                                        <Button color="danger"
                                            onClick={() => this.deleteBrand(academicYear.academicYearId)}>
                                            Xoá
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

            </Container>
        );
    }
}

export default AcademicYearManagement;