import { useState, useEffect } from "react";
import { Col, Container, CustomInput, Row } from "reactstrap";
import "react-tabs/style/react-tabs.css";
import { useHistory } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { endpointGlv, getWithAuth } from "../../components/HttpUtils";
import {
  formatDate,
  formatSyncedDate,
  getFileName,
} from "../../components/Helper";
import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import AttendanceTimeRangeGrouping from "./AttendanceTimeRangeGrouping";
import '../../App.css';

const AttendanceManagementDate = () => {
  const history = useHistory();
  const [attendanceList, setAttendanceList] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [useAdvancedFiltering, setUseAdvancedFiltering] = useState(false);
  const [loading, setLoading] = useState(false);

  const styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};

  useEffect(() => {
    fetchAllAttendance(formatDate(new Date().toISOString().split('T')[0])).then(()=>setLoading(false));
  }, []);

  const onItemClick = (id) => {
    history.push(`/attendances/detail/${id}`);
  };

  const handleSimpleDateChanged = (event) => {
    setDate(event.target.value);
    let dateStr = formatDate(event.target.value);
    fetchAllAttendance(dateStr).then(()=>setLoading(false));
  }

  const fetchAllAttendance = async (dateStr) => {
    if (dateStr !== "") {
      setLoading(true)
      await getWithAuth(endpointGlv + "/attendances/filter-by-date/" + dateStr)
        .then((response) => {
          if (response.status === 200) {
            setAttendanceList(response.data);
          }
        })
        .catch((error) =>
          console.log("Fetching attendances by date error: " + error)
        );
    }
  };

  return (
    <Container>
      <Row>
        <Col hidden={useAdvancedFiltering}>
            <strong>Chọn ngày: </strong>
            <input id="simple-date-selector"
              style={{ width: "10rem", marginLeft: "1rem" }}
              type="date" max={new Date().toISOString().split('T')[0]}
              value={new Date(date).toISOString().split('T')[0]}
              onChange={(e) => handleSimpleDateChanged(e)}
            />
        </Col>

        <Col>
          <CustomInput type="checkbox" id="advanceFiltering" name="advanceFiltering" 
                      defaultChecked={useAdvancedFiltering} label="Lọc nâng cao"
                      checked={useAdvancedFiltering} onChange={(e) => setUseAdvancedFiltering(e.target.checked)} />
        </Col>
          

          
          <br></br>
          <br></br>
          <hr />
      </Row>

      <Row>
                <div style={styles}>
                <ScaleLoader
                    color="brown"
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>
      </Row>

      {useAdvancedFiltering ? <AttendanceTimeRangeGrouping/> : 
        <Row className='table-responsive'>
        {(attendanceList.length > 0) && (
          <div className="table-responsive">
            <p style={{float: "right"}}>Số lượng: <b>{attendanceList.length}</b></p>
            <table
              className="table table-hover table-secondary"
              id="attendance-by-date"
            >
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Thông tin</th>
                  <th>Ngày quét</th>
                  <th>GLV quét</th>
                  <th>Ngày đồng bộ</th>
                </tr>
              </thead>
              <tbody>
                {attendanceList.map((attendance, index) => (
                  <tr
                    key={index}
                    onClick={() => onItemClick(attendance.info.substring(0, 5))}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{index + 1}</td>
                    <td>{attendance.info}</td>
                    <td>{attendance.scannedDate}</td>
                    <td>{attendance.scannedBy}</td>
                    <td>{formatSyncedDate(attendance.syncedDate)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Row>
      }
      

      {(attendanceList.length > 0 && !useAdvancedFiltering) ? (
        <ReactHTMLTableToExcel
          id="attendance-by-date-exporter"
          className="download-table-xls-button"
          table="attendance-by-date"
          filename={getFileName()}
          sheet={date}
          buttonText="Xuất File Excel"
        />
      ) : (
        undefined
      )}
    </Container>
  );
};

export default AttendanceManagementDate;
