export const messages = {
  usernameLength: "(*) Độ dài của tên đăng nhập từ 3 đến 40 kí tự",
  passwordLength: "(*) Độ dài của mật khẩu từ 4 đến 40 kí tự",
  loginFailed: "(*) Tên đăng nhập hoặc password không đúng!",
  usernameContainsSpace: "(*) Tên đăng nhập không được chứa khoảng trắng!",
  fullNameLength: "(*) Độ dài của tên từ 3 đến 40 kí tự",
  invalidEmailFormat: "(*) Email không đúng định dạng",
  invalidPhoneNumberFormat: "(*) Số điện thoại không đúng định dạng",
  phoneNumberLength: "(*) Số điện thoại gồm 8 đến 14 kí tự",
  registerSuccess: "Đăng ký thành công, vui lòng đăng nhập để tiếp tục!",
  registerFailed: "Đăng ký thất bại, tên đăng nhập hoặc email đã bị trùng!",
  currentPassword: "Bạn đã nhập vào mật khẩu hiện tại",
  confirmPasswordNotMatch: "Mật khẩu xác nhận không khớp với mật khẩu mới!",
  changePasswordSuccess:
    "Đổi mật khẩu thành công. Vui lòng đăng nhập để tiếp tục!",
  changePasswordFailed:
    "Đổi mật khẩu thất bại. Vui lòng kiểm tra đường truyền và thông tin!",
  logOutSuccess: "Đăng xuất thành công!",
  resetPasswordSuccess:
    "Tạo mới password thành công! Vui lòng kiểm tra email của bạn!",
  resetPasswordFailed:
    "Tạo mới password thất bại! Vui lòng kiểm tra đường truyền và thông tin!",
  updateUserSuccess: "Cập nhật thông tin người dùng thành công!",
  updateUserFailed: "Cập nhật thông tin người dùng thất bại!",
  deleteConfirm: "Bạn có thực sự muốn xóa?",
  deleteSuccess: "Xóa thành công!",
  deleteFailed: "Xóa thất bại! ",
  insertSuccess: "Thêm mới thành công!",
  insertFailed: "Thêm mới thất bại!",
  updateFailed: "Cập nhật thất bại!",
  updateSuccess: "Cập nhật thành công!",
  categoryIdLength: "Độ dài của mã thể loại từ 3 đến 8 kí tự",
  categoryNameLength: "Độ dài của tên thể loại từ 3 đến 40 kí tự",
  bookNameLength: "Độ dài của tên sách từ 6 đến 250 kí tự",
  bookPrice: "Giá bán phải lớn hơn 1000 VND",
  selectAuthor: "Vui lòng chọn tác giả cho sách!",
  brand_ManufacturerNameLength: "Độ dài tên từ 3 đến 50 kí tự",
  addressLength: "Địa chỉ từ 5 đến 50 kí tự",
  countryLength: "Tên quốc gia từ 2 đến 10 kí tự",
  addressUserOrder: "Đia chỉ từ 5 đến 100 kí tự",
  orderFailed: "Đặt hàng thất bại",
  orderSuccess: "Đặt hàng thành công!",
  updateRating: "Xin cảm ơn đánh giá của bạn!",
  insertRating: "Xin cảm ơn đánh giá mới của bạn!",
  loginToProceed: "Vui lòng đăng nhập để tiếp tục!",
  roleSelect: "Vui lòng chọn vai trò cho người dùng!",
  updateAfterDeleted: "Cập nhật dữ liệu sau xóa!",
  selectPayment: "Vui lòng chọn hình thức thanh toán!",
  birthdayRequired: "(*) Vui lòng chọn ngày sinh",
  emptyUser: "(*) Thông tin chưa đầy đủ",
  userNotFound: "(*) Mã thiếu nhi không tồn tại!",
  userExistedInClass: "(*) Thiếu nhi đã có trong lớp này!",
};
