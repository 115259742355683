import React, { useState, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Link } from "react-router-dom";
import { Col, CustomInput, Input, Label, Row, Button } from "reactstrap";
import { toast } from "react-toastify";
import {
  addDateStrRepresentation,
  formateDateString,
  getFileName,
} from "../../components/Helper";
import {
  get,
  endpointPublic,
  endpointGlv,
  getWithAuth,
} from "../../components/HttpUtils";
import ScaleLoader from "react-spinners/ScaleLoader";
import "../../App.css";
import { styles } from "../../components/Helper";

toast.configure();
const AttendanceStatisticAll = () => {
  const [classList, setClassList] = useState([]);
  const [filterClass, setFilterClass] = useState("");
  const [attendanceList, setAttendanceList] = useState([]);
  const [historyChecked, setHistoryChecked] = useState(false);
  const [fullColumnChecked, setFullColumnChecked] = useState(false);
  const [activeAcademicYearName, setActiveAcademicYearName] = useState("");
  const [loading, setLoading] = useState(false);
  const [absentList, setAbsentList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentStatisticedClass, setCurrentStatisticedClass] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    getCurrentClasses();
    getCurrentAcademicYear();
    if (filterClass !== "" && startDate !== "") {
      fetchAbsentFromClass(filterClass, startDate, endDate);
    }
  }, [filterClass, startDate, endDate]);

  const getCurrentClasses = () => {
    get(endpointPublic + "/classes/current")
      .then((response) => {
        if (response.status === 200) {
          setClassList(response.data);
        }
      })
      .catch((error) =>
        console.log("Fetching current classes error: " + error),
      );
  };

  const getCurrentAcademicYear = () => {
    get(endpointPublic + "/active-academic-year")
      .then((response) => {
        if (response.status === 200) {
          setActiveAcademicYearName(response.data.name);
        }
      })
      .catch((error) =>
        console.log("Fetching current classes error: " + error),
      );
  };

  const handleFilterChange = (event) => {
    setFilterClass(event.target.value);
    // fetchAttendanceByClassId(event.target.value).then(()=>setLoading(false));
  };

  const validateForm = (classId, startDate) => {
    let errors = {},
      formIsValid = true;
    if (classId === "" || classId === undefined) {
      errors["classId"] = "Vui lòng chọn lớp";
      formIsValid = false;
    }
    if (startDate === "" || startDate === undefined) {
      errors["startDate"] = "Vui lòng chọn ngày bắt đầu";
      formIsValid = false;
    }
    setErrors(errors);

    return formIsValid;
  };

  const fetchAttendanceByClassId = async (classId, startDate, endDate) => {
    if (!validateForm(classId, startDate)) {
      return;
    }

    setStartDate(startDate);
    setEndDate(endDate);
    setCurrentStatisticedClass(filterClass.substring(2));
    setLoading(true);
    await getWithAuth(
      `${endpointGlv}/statistic-by-class/${classId}?startDate=${startDate}&endDate=${endDate}`,
    )
      .then((response) => {
        if (response.status === 200) {
          setAttendanceList(response.data);
        }
      })
      .catch((error) => console.log("Fetching statistic error: " + error));
  };

  const fetchAbsentFromClass = async (classId, startDate, endDate) => {
    if (!validateForm(classId, startDate)) {
      return;
    }

    await getWithAuth(
      `${endpointGlv}/statistic-absent-by-class/${classId}?startDate=${startDate}&endDate=${endDate}`,
    )
      .then((response) => {
        if (response.status === 200) {
          setAbsentList(response.data);
        }
      })
      .catch((error) => console.log("Fetching statistic error: " + error));
  };

  const handleHistoryCheckboxChanged = (event) => {
    setHistoryChecked(event.target.checked);
  };

  const handleHFullColumnCheckboxChanged = (event) => {
    setFullColumnChecked(event.target.checked);
  };

  const processLeaveInfoDetails = (info) => {
    let details = info.split("\n");
    if (details.length > 1) {
      return (
        <p>
          {details[0] + " - "} <b>{details[1]}</b>
        </p>
      );
    } else return <p>{details[0]}</p>;
  };

  const getLeaveFromAbsentDate = (absentList) => {
    return absentList.filter((day) => day.includes("(P)")).length;
  };

  const filterAbsentByUserId = (userId, option) => {
    for (let absent of absentList) {
      if (option === 0 && absent.userId === userId) {
        let giaoLyLeaveDates = getLeaveFromAbsentDate(absent.absentGiaoLy);
        return (
          <>
            <li>
              VP: <b>{giaoLyLeaveDates}</b>
            </li>
            <li>
              KP: <b>{absent.absentGiaoLy.length - giaoLyLeaveDates}</b>
            </li>
          </>
        );
      } else if (option === 1 && absent.userId === userId) {
        let leSomLeaveDates = getLeaveFromAbsentDate(absent.absentLeSom);
        return (
          <>
            <li>
              VP: <b>{leSomLeaveDates}</b>
            </li>
            <li>
              KP: <b>{absent.absentLeSom.length - leSomLeaveDates}</b>
            </li>
          </>
        );
      } else if (option === 2 && absent.userId === userId) {
        let leSomLeaveDates = getLeaveFromAbsentDate(
          absent.absentLeSomNotSameWithAbsentGiaoLyDates,
        );
        return (
          <>
            <li>
              VP: <b>{leSomLeaveDates}</b>
            </li>
            <li>
              KP:{" "}
              <b>
                {absent.absentLeSomNotSameWithAbsentGiaoLyDates.length -
                  leSomLeaveDates}
              </b>
            </li>
          </>
        );
      }
    }
  };

  const filterAbsentHistoryByUserId = (userId, option) => {
    for (let absent of absentList) {
      if (option === 0 && absent.userId === userId)
        return absent.absentGiaoLy.map((date) => <li>{date}</li>);
      else if (option === 1 && absent.userId === userId)
        return absent.absentLeSom.map((date) => <li>{date}</li>);
      else if (option === 2 && absent.userId === userId)
        return absent.absentLeSomNotSameWithAbsentGiaoLyDates.map((date) => (
          <li>{date}</li>
        ));
    }
  };

  const renderTableData = (attendanceList) => {
    if (historyChecked) {
      return attendanceList.map((attendance, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>
              <Link
                to={{ pathname: `/attendances/detail/` + attendance.userId }}
                target="_blank"
              >
                &nbsp;{attendance.userId}
              </Link>
            </td>
            <td>{attendance.hollyName}</td>
            <td>{attendance.lastName}</td>
            <td>{attendance.firstName}</td>
            <td>
              <ul>
                {attendance.historyT3T5.map((date) => (
                  <li>{addDateStrRepresentation(date)}</li>
                ))}
              </ul>
            </td>
            <td>
              <ul>
                {attendance.historyKhac.map((date) => (
                  <li>{addDateStrRepresentation(date)}</li>
                ))}
              </ul>
            </td>
            <td>
              <ul>{filterAbsentHistoryByUserId(attendance.userId, 0)}</ul>
            </td>

            <td>
              <ul>{filterAbsentHistoryByUserId(attendance.userId, 2)}</ul>
            </td>
            {fullColumnChecked && (
              <td>
                <ul>{filterAbsentHistoryByUserId(attendance.userId, 1)}</ul>
              </td>
            )}
            {fullColumnChecked && (
              <td>
                <ul>
                  {attendance.historyGiaoLy.map((date) => (
                    <li>{date}</li>
                  ))}
                </ul>
              </td>
            )}
            {fullColumnChecked && (
              <td>
                <ul>
                  {attendance.historyLeSom.map((date) => (
                    <li>{date}</li>
                  ))}
                </ul>
              </td>
            )}

            <td>{processLeaveInfoDetails(attendance.notes)}</td>
          </tr>
        );
      });
    } else {
      return attendanceList.map((attendance, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>
              <Link
                to={{ pathname: `/attendances/detail/` + attendance.userId }}
                target="_blank"
              >
                &nbsp;{attendance.userId}
              </Link>
            </td>
            <td>{attendance.hollyName}</td>
            <td>{attendance.lastName}</td>
            <td>{attendance.firstName}</td>
            <td>{attendance.historyT3T5.length}</td>
            <td>{attendance.historyKhac.length}</td>
            <td>{filterAbsentByUserId(attendance.userId, 0)}</td>
            <td>{filterAbsentByUserId(attendance.userId, 2)}</td>

            {fullColumnChecked && (
              <td>{filterAbsentByUserId(attendance.userId, 1)}</td>
            )}
            {fullColumnChecked && <td>{attendance.historyGiaoLy.length}</td>}
            {fullColumnChecked && <td>{attendance.historyLeSom.length}</td>}
            <td>{processLeaveInfoDetails(attendance.notes)}</td>
          </tr>
        );
      });
    }
  };

  const getTableTitle = () => {
    if (startDate === "" && endDate === "") {
      return (
        <h4>
          TỔNG KẾT ĐIỂM DANH TỪ ĐẦU NĂM - LỚP {currentStatisticedClass} (
          {activeAcademicYearName})
        </h4>
      );
    } else if (startDate !== "" && endDate !== "") {
      return (
        <h4>
          TỔNG KẾT ĐIỂM DANH TỪ NGÀY {formateDateString(startDate)} ĐẾN NGÀY{" "}
          {formateDateString(endDate)} - LỚP {currentStatisticedClass} (
          {activeAcademicYearName})
        </h4>
      );
    } else if (startDate !== "") {
      return (
        <h4>
          TỔNG KẾT ĐIỂM DANH TỪ NGÀY {formateDateString(startDate)} - LỚP{" "}
          {currentStatisticedClass} ({activeAcademicYearName})
        </h4>
      );
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Label for="class-academic-year">
            <b>Chọn lớp: </b>
          </Label>
          <Input
            type="select"
            name="class-academic-year"
            style={{ width: "20rem" }}
            onChange={(e) => handleFilterChange(e)}
          >
            <option>---------------Lớp học---------------</option>
            {classList.map((currentClass) => (
              <option key={currentClass.classId} value={currentClass.classId}>
                {currentClass.name}
              </option>
            ))}
          </Input>
          <span style={{ color: "red" }}>{errors["classId"]}</span>
        </Col>

        <Col>
          <Label for="start-date">
            <b>Ngày bắt đầu:</b>
          </Label>
          <Input
            type="date"
            name="start-date"
            lang="vn-CA"
            value={startDate}
            max={new Date().toISOString().split("T")[0]}
            placeholder="Nhập ngày bắt đầu"
            onChange={(e) => setStartDate(e.target.value)}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          <span style={{ color: "red" }}>{errors["startDate"]}</span>
        </Col>

        <Col>
          <Label for="end-date">
            <b>Ngày kết thúc:</b>
          </Label>
          <Input
            type="date"
            name="end-date"
            value={endDate}
            max={new Date().toISOString().split("T")[0]}
            placeholder="Nhập ngày kết thúc"
            onChange={(e) => setEndDate(e.target.value)}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
        </Col>

        <Col>
          <br />
          <CustomInput
            type="checkbox"
            id="historyCheckbox"
            name="historyCheckbox"
            defaultChecked={historyChecked}
            label="Hiển thị lịch sử"
            checked={historyChecked}
            onChange={(e) => handleHistoryCheckboxChanged(e)}
          />
        </Col>

        <Col>
          <br />
          <CustomInput
            type="checkbox"
            id="fullColumnCheckBox"
            name="fullColumnCheckBox"
            defaultChecked={fullColumnChecked}
            label="Hiển thị đủ cột"
            checked={fullColumnChecked}
            onChange={(e) => handleHFullColumnCheckboxChanged(e)}
          />
        </Col>

        <Col>
          <br />
          <Button
            color="dark"
            onClick={() =>
              fetchAttendanceByClassId(filterClass, startDate, endDate).then(
                () => setLoading(false),
              )
            }
          >
            Lọc
          </Button>
        </Col>
      </Row>

      <Row>
        <div style={styles}>
          <ScaleLoader
            color="brown"
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </Row>

      {attendanceList.length > 0 && (
        <Row className="table-responsive">
          <table
            style={{ marginTop: "1rem" }}
            className="table table-hover table-bordered"
            id="attendance-checking"
          >
            <caption style={{ captionSide: "top", textAlign: "center" }}>
              {getTableTitle()}
            </caption>
            <caption
              style={{ captionSide: "top", textAlign: "right" }}
              hidden={true}
            >
              GIÁO XỨ THÁNH GIUSE
            </caption>
            <caption style={{ captionSide: "bottom", textAlign: "left" }}>
              <strong>
                <u>*Chú Thích:</u> VP (Vắng Phép) - KP (Vắng Không Phép)
              </strong>
            </caption>
            <thead>
              <tr>
                <th>STT</th>
                <th>ID</th>
                <th>Tên Thánh</th>
                <th>Họ và tên đệm</th>
                <th>Tên</th>
                <th>
                  <b>DỰ</b> lễ thứ 3, 5 (ngày)
                </th>
                <th>
                  <b>DỰ</b> lễ thứ 2,4,6 (ngày)
                </th>
                <th>
                  <b>Vắng</b> học giáo lý (ngày)
                </th>
                <th>
                  <b>Vắng</b> đi lễ sớm (ngày)
                </th>
                {fullColumnChecked && (
                  <th>
                    <b>Vắng</b> đi lễ sớm toàn bộ (ngày)
                  </th>
                )}
                {fullColumnChecked && <th>Học giáo lý (ngày)</th>}
                {fullColumnChecked && <th>Đi lễ sớm (ngày)</th>}
                <th>Ghi chú</th>
              </tr>
            </thead>
            <tbody>{renderTableData(attendanceList)}</tbody>
          </table>
        </Row>
      )}

      <br />
      <hr />
      {attendanceList.length > 0 ? (
        <div style={{ float: "right" }}>
          <ReactHTMLTableToExcel
            id="attendance-checking"
            className="download-table-xls-button"
            table="attendance-checking"
            filename={getFileName()}
            sheet={filterClass}
            buttonText="Xuất File Excel"
          />
        </div>
      ) : undefined}

      {/* {filterClass !== undefined && filterClass !== null && filterClass !== '' && <AbsentStatisticAll classId={filterClass}>Thống kê ngày vắng</AbsentStatisticAll>} */}
    </>
  );
};

export default AttendanceStatisticAll;
