import React, { Component } from "react";

class About extends Component {
  state = {};
  render() {
    return (
      <div>
        <h1 className="alert alert-dark" align="center">
          KHUNG GIỜ ĐIỂM DANH
        </h1>
        <table style={{ margin: "auto" }}>
          <thead>
            <tr>
              <th></th>
              <th>NGÀY TRONG TUẦN</th>
              <th>SÁNG</th>
              <th>CHIỀU</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Thứ 3, Thứ 5</td>
              <td>Thứ 3, Thứ 5</td>
              <td>5h - 6h</td>
              <td>6h - 7h</td>
            </tr>
            <tr>
              <td>Học Giáo Lý</td>
              <td>CHÚA NHẬT</td>
              <td>7h - 8h30</td>
              <td>3h30 - 4h và 5h - 6h</td>
            </tr>
            <tr>
              <td>Đi lễ Chúa Nhật sớm</td>
              <td>CHÚA NHẬT</td>
              <td>6h - 6h15</td>
              <td></td>
            </tr>
            <tr>
              <td>Khác (đi lễ bù, thi đua,...)</td>
              <td>THỨ 2, THỨ 4 , THỨ 6</td>
              <td>5h - 6h</td>
              <td>6h - 7h</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default About;
