import React, { Component } from "react";
import { Link } from "react-router-dom";
import { BiLogIn } from "react-icons/bi";
import "./navbar.css";

const permissions = {
  ROLE_ADMIN: [
    {
      displayItem: "Quản lý điểm danh",
      endpoint: "/attendances",
    },
    {
      displayItem: "Quản lý thẻ",
      endpoint: "/cards",
    },
    {
      displayItem: "Quản lý vắng phép",
      endpoint: "/admin/leaves",
    },
    {
      displayItem: "Quản lý người dùng",
      endpoint: "/admin/users",
    },
    {
      displayItem: "Quản lý lớp",
      endpoint: "/admin/classes",
    },
    {
      displayItem: "Quản lý năm học",
      endpoint: "/admin/academic-years",
    },
    {
      displayItem: "Quản lý khối",
      endpoint: "/admin/grades",
    },
  ],
  ROLE_GLV: [
    {
      displayItem: "Quản lý điểm danh",
      endpoint: "/attendances",
    },
    {
      displayItem: "Quản lý thẻ",
      endpoint: "/cards",
    },
  ],
};

class Navbar extends Component {
  state = { loginName: "" };

  renderWhenLoggedIn(roleName) {
    if (roleName === undefined) return undefined;
    return permissions[roleName].map((permission, index) => (
      <li className="nav-item" key={index}>
        <Link
          className="nav-link"
          to={permission.endpoint}
          onClick={this.handleNavItemClick}
        >
          {permission.displayItem}
        </Link>
      </li>
    ));
  }

  getRole() {
    let roles = localStorage.getItem("role");
    if (roles !== null && roles !== undefined) {
      roles = roles.split(", ");
      console.log("ROle from local storage: ", roles);
      if (roles.includes("ROLE_ADMIN")) return "ROLE_ADMIN";
      else if (roles.includes("ROLE_GLV")) return "ROLE_GLV";
      else if (roles.includes("ROLE_STUDENT")) return "ROLE_STUDENT";
    } else return undefined;
  }

  doesUserLoggedIn() {
    return (
      localStorage.getItem("userId") !== undefined &&
      localStorage.getItem("userId") !== null &&
      localStorage.getItem("userId") !== ""
    );
  }

  componentDidMount() {
    const roleName = this.getRole();
    this.setState({ role: roleName });
  }

  handleNavItemClick() {
    const togglerButton = document.getElementById("navbar-toggler-button");
    if (
      window.innerWidth < 990 &&
      togglerButton !== undefined &&
      togglerButton !== null
    )
      togglerButton.click();
  }

  render() {
    return (
      <nav
        className="navbar navbar-expand-lg navbar-light fixed-top"
        style={{ backgroundColor: "#e3f2fd" }}
      >
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img
              alt="logo"
              width="30rem"
              height="auto"
              src={window.location.origin + "/app_logo.png"}
            />
            <b style={{ marginLeft: "0.2rem" }}>TNTT GX. THÁNH GIUSE</b>
          </Link>
          <button
            id="navbar-toggler-button"
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link
                  className="nav-link active"
                  aria-current="page"
                  to="/"
                  onClick={this.handleNavItemClick}
                >
                  Trang chủ
                </Link>
              </li>
              {this.renderWhenLoggedIn(this.state.role)}
              {this.doesUserLoggedIn() && (
                <li className="nav-item dropdown">
                  <div
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Tài khoản
                  </div>
                  <ul className="dropdown-menu">
                    <Link
                      className="link dropdown-item"
                      to="/account/logout"
                      onClick={this.handleNavItemClick}
                    >
                      Đăng xuất
                    </Link>
                    <Link
                      className="link dropdown-item"
                      to={{
                        pathname:
                          `/account/change-password/` +
                          localStorage.getItem("userId"),
                      }}
                      onClick={this.handleNavItemClick}
                    >
                      Đổi mật khẩu
                    </Link>
                  </ul>
                </li>
              )}
            </ul>
            <span className="navbar-text">
              {localStorage.getItem("userId") ? (
                <div>
                  <BiLogIn />
                  <b style={{ marginLeft: "0.1rem" }}>
                    {localStorage.getItem("userId")}
                  </b>
                </div>
              ) : undefined}
            </span>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
